import axios from 'axios'

export type LoginAcademyResponse = {
	academyLoginUrl: string
}

export default async function academyLogin(email: string, apiVersion: string = process.env.REACT_APP_LOGIN_API_VERSION ) {
	return await axios
		.post<LoginAcademyResponse>(
			`${process.env.REACT_APP_AUTH_API_URL}/api/academy/login`,
			{ user: email },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
					'x-api-version': `${apiVersion}`
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			console.error(error)
			return error.response.data
		})
}
