import { useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Card, CardActionArea } from '@mui/material'
import { useCookies } from 'react-cookie'
import ReactCardFlip from 'react-card-flip'
import ServiceCardContent from './ServiceCardContent'
import ServiceCardInfo from './ServiceCardInfo'
import { LanguageContext } from '../../../context/languageContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import { useAuthContext } from '../../../context/authContext'
import { useStatusContext } from '../../../context/statusContext'
import useCardFlip from '../../hooks/userCardFlip'
import useLaunchHUB from '../../hooks/useLaunchHub'
import useChargeBeeLoginLink from '../../hooks/useChargeBeeLogin'
import { ServiceData } from '../../types/types.d'

const ServiceCard = (props: ServiceData) => {
	let {
		link,
		bgImage,
		title,
		subtitle,
		text,
		cardNumber,
		service,
		isInMaintenance,
	} = props
	const navigate = useNavigate()
	const [cookies, setCookie, removeCookie] = useCookies()
	const componentRef = useRef<HTMLDivElement | null>(null)

	const { status } = useStatusContext()
	const { addSuccess } = useSuccessMessageContext()
	const { addError } = useErrorMessageContext()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const { user } = useAuthContext()

	const { handleFlip, isFlipped } = useCardFlip(componentRef, status, service)

	const { handleLaunchHUB } = useLaunchHUB(
		user,
		language,
		addSuccess,
		addError,
		translate
	)

	const { fetchLoginLink } = useChargeBeeLoginLink(
		status,
		user,
		addError,
		translate
	)

	const handleClick = async () => {
		if (status.connection !== 'online') {
			return addError(`${translate('error_connection')}`)
		}

		if (isInMaintenance) {
			navigate('/maintenancePage')
			return
		}

		switch (service) {
			case 'shop':
				const chargeBeeLink = await fetchLoginLink()
				return window.open(chargeBeeLink, '_blank')
			default:
				return window.open(link, '_blank')
		}
	}

	const setOpacity = () => {
		if (status.connection !== 'online' && service !== 'hub') {
			return '0.50'
		}
		return '1'
	}

	return (
		<>
			{service === 'academy' && (
				<Box component={'div'}>
					<ReactCardFlip
						isFlipped={isFlipped}
						flipSpeedBackToFront={0.5}
						flipSpeedFrontToBack={0.5}
					>
						<CardActionArea onClick={handleClick}>
							<Card
								sx={{
									opacity: setOpacity,
									width: '100%',
									height: '25vh',
									borderRadius: '15px',
									display: 'flex',
								}}
								onMouseEnter={(event) => handleFlip(event)}
							>
								<ServiceCardContent
									bgImage={bgImage}
									title={title}
									subtitle={subtitle}
									text={text}
									cardNumber={cardNumber}
								/>
							</Card>
						</CardActionArea>
						<CardActionArea onClick={handleClick}>
							<Card
								sx={{
									width: '100%',
									height: '25vh',
									borderRadius: '15px',
									display: 'flex',
									alignItems: 'center',
								}}
								onMouseLeave={(event) => handleFlip(event)}
							>
								<ServiceCardInfo subtitle={subtitle} text={text} />
							</Card>
						</CardActionArea>
					</ReactCardFlip>
				</Box>
			)}
			{service === 'lrs' && (
				<Box component={'div'}>
					<ReactCardFlip
						isFlipped={isFlipped}
						flipSpeedBackToFront={0.5}
						flipSpeedFrontToBack={0.5}
					>
						<CardActionArea onClick={handleClick}>
							<Card
								sx={{
									opacity: setOpacity,
									width: '100%',
									height: '25vh',
									borderRadius: '15px',
									display: 'flex',
								}}
								onMouseEnter={(event) => handleFlip(event)}
							>
								<ServiceCardContent
									bgImage={bgImage}
									title={title}
									subtitle={subtitle}
									text={text}
									cardNumber={cardNumber}
								/>
							</Card>
						</CardActionArea>
						<CardActionArea onClick={handleClick}>
							<Card
								sx={{
									opacity: setOpacity,
									width: '100%',
									height: '25vh',
									borderRadius: '15px',
									display: 'flex',
									alignItems: 'center',
								}}
								onMouseLeave={(event) => handleFlip(event)}
							>
								<ServiceCardInfo subtitle={subtitle} text={text} />
							</Card>
						</CardActionArea>
					</ReactCardFlip>
				</Box>
			)}
			{service === 'hub' && (
				<Box component={'div'}>
					<ReactCardFlip
						isFlipped={isFlipped}
						flipSpeedBackToFront={0.5}
						flipSpeedFrontToBack={0.5}
					>
						<CardActionArea onClick={handleLaunchHUB}>
							<Card
								sx={{
									opacity: setOpacity,
									width: '100%',
									height: '25vh',
									borderRadius: '15px',
									display: 'flex',
								}}
								onMouseEnter={(event) => handleFlip(event)}
							>
								<ServiceCardContent
									bgImage={bgImage}
									title={title}
									subtitle={subtitle}
									text={text}
									cardNumber={cardNumber}
								/>
							</Card>
						</CardActionArea>
						<CardActionArea onClick={handleLaunchHUB}>
							<Card
								sx={{
									width: '100%',
									height: '25vh',
									borderRadius: '15px',
									display: 'flex',
									alignItems: 'center',
								}}
								onMouseLeave={(event) => handleFlip(event)}
							>
								<ServiceCardInfo subtitle={subtitle} text={text} />
							</Card>
						</CardActionArea>
					</ReactCardFlip>
				</Box>
			)}
			{service === 'shop' && (
				<Box component={'div'}>
					<ReactCardFlip
						isFlipped={isFlipped}
						flipSpeedBackToFront={0.5}
						flipSpeedFrontToBack={0.5}
					>
						<CardActionArea onClick={handleClick}>
							<Card
								sx={{
									opacity: setOpacity,
									width: '100%',
									height: '25vh',
									borderRadius: '15px',
									display: 'flex',
								}}
								onMouseEnter={(event) => handleFlip(event)}
							>
								<ServiceCardContent
									bgImage={bgImage}
									title={title}
									subtitle={subtitle}
									text={text}
									cardNumber={cardNumber}
								/>
							</Card>
						</CardActionArea>
						<CardActionArea onClick={handleClick}>
							<Card
								sx={{
									width: '100%',
									height: '25vh',
									borderRadius: '15px',
									display: 'flex',
									alignItems: 'center',
								}}
								onMouseLeave={(event) => handleFlip(event)}
							>
								<ServiceCardInfo subtitle={subtitle} text={text} />
							</Card>
						</CardActionArea>
					</ReactCardFlip>
				</Box>
			)}
		</>
	)
}

export default ServiceCard
