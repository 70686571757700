import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material'
import { useAuthContext } from '../../context/authContext'
import { LanguageContext } from '../../context/languageContext'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { useStatusContext } from '../../context/statusContext'
import ServiceCard from '../components/cards/ServiceCard'
import { cardConfig } from '../utils/cardConfig'
import useSetAcademyUrlLogin from '../hooks/useSetAcademyUrlLogin'
import useUserServiceCards from '../hooks/useConfigServiceCards'
import useRemoveCookiesOnClose from '../hooks/useRemoveCookiesOnClose'
import useDecodeUserToken from '../hooks/useDecodeUserToken'
import { underMaintenance } from '../utils/underMaintenance'
import { ServiceData } from '../types/types.d'
import { useVersion } from '../../context/appVersionContext'
import MainWrapper from '../components/common/MainWrapper'
import useSetLRSCookie from '../hooks/useSetLRSCookie'

const Services = () => {
	const { user, addUser, removeUser } = useAuthContext()
	const [cookies, setCookie, removeCookie] = useCookies()
	const { appVersion } = useVersion()
	const { addError } = useErrorMessageContext()
	const { status } = useStatusContext()
	let navigate = useNavigate()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const { maintenance, formattedDate } = underMaintenance(language)

	const [userServices, setUserServices] = useState<ServiceData[]>([])

	const [academy, setAcademy] = useState({
		loginURL: '',
	})

	const [chargeBee, setChargeBee] = useState({
		loginURL: '',
	})

	useEffect(() => {
		useSetLRSCookie(user, setCookie, addError, translate)
	}, [user])

	useEffect(() => {
		useSetAcademyUrlLogin(user, status, setAcademy, addError, translate)
	}, [user])

	useEffect(() => {
		const autologin = async () => {
			await useDecodeUserToken(cookies, addUser, addError, navigate, translate)
		}
		autologin()
	}, [])

	useEffect(() => {
		useUserServiceCards(user, academy, cardConfig, chargeBee, setUserServices)
	}, [user, academy])

	useRemoveCookiesOnClose(user, removeUser, cookies, removeCookie)

	const [openMyProfile, setOpenMyProfile] = useState(false)

	const handleClickMyProfile = () => {
		setOpenMyProfile(!openMyProfile)
	}

	const [openSupportForm, setOpenSupportForm] = useState(false)

	const handleClickSupportForm = () => {
		if (status.connection !== 'online') {
			return addError(`${translate('error_connection')}`)
		}
		setOpenSupportForm(!openSupportForm)
	}

	return (
		<Box
			sx={{
				flexGrow: 1,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<MainWrapper>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Grid container justifyContent="center" spacing={0}>
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							{maintenance && (
								<Alert severity="warning"
									sx={{
										position: 'relative',
										zIndex: 1,
										width: 'auto',
										maxWidth: 700,
										fontSize: {
											xs: 10,
											sm: 10,
											md: 14,
											lg: 14,
											xl: 14
										},
									}}>
									<strong>{`${translate('title_maintenance')}`}</strong>
									<br />
									{`${translate('text_maintenance_pt1')} ${formattedDate}. ${translate(
										'text_maintenance_pt3'
									)}`}
									<br />
									{`${translate('text_maintenance_pt2')}`}
								</Alert>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexWrap: 'wrap',
								justifyContent: 'center',
								gap: 2,
								width: '100%',
								maxWidth: '1200px',
							}}
						>
							{userServices.map((service, index) => (
								<Box
									key={service.service || index}
									sx={{
										flex: {
											xs: '1 1 100%',
											sm: '1 1 calc(0% - 16px)',
											lg: index < 3 ? '1 1 calc(33.33% - 16px)' : '1 1 calc(50% - 16px)',
										},
										minWidth: {
											xs: '250px',
											sm: '300px',
											md: '280px',
											lg: '300px',
											xl: '360px',
										},
										maxWidth: {
											xs: '300px',
											sm: '360px',
											md: '360px',
											lg: '360px',
											xl: '360px',
										},
										width: '100%',
									}}
								>
									<ServiceCard {...service} />
								</Box>
							))}
						</Box>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					justifyContent="center"
					wrap="nowrap"
					alignItems="flex-end"
					sx={{ mt: '2vh' }}
				>
					<Box
						sx={{
							zIndex: '0 !important',
							width: { md: '100%', lg: '100%', xl: '100%' },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'stretch',
							alignItems: 'center !important',
						}}
					>
						<Divider
							variant="middle"
							sx={{
								color: '#35e398',
								borderColor: '#35e398',
								width: { xs: '100%', sm: '100%', md: '90%', lg: '50%', xl: '50%' },
								borderWidth: 1,
							}}
						/>
						<Typography
							variant="h5"
							sx={{
								mt: 2,
								color: 'white',
								fontSize: {
									xs: '16px',
									sm: '18px',
									md: '22px',
									lg: '24px',
									xl: '26px',
								},
								marginX: 0,
								textAlign: 'center',
							}}
						>
							{translate('text_service_pt1')}
						</Typography>
						<Typography
							variant="caption"
							sx={{
								color: 'white',
								fontSize: { xs: '12px', sm: '14px', md: '16px', lg: '18px', xl: '20px' },
								marginX: 0,
								textAlign: 'center',
								fontFamily: 'Barlow Condensed',
							}}
						>
							{translate('text_service_pt2')}
						</Typography>
					</Box>
				</Grid>
				<Box
					sx={{
						position: {
							xs: 'relative',
							sm: 'relative',
							md: 'relative',
							lg: 'relative',
							xl: 'absolute',
						},
						left: { xs: 0, sm: 0, md: 0, lg: 0, xl: 16 },
						bottom: { xs: 0, sm: 0, md: 0, lg: 0, xl: 16 },
						top: { xs: 30, sm: 30, md: 30, lg: 30, xl: 16 },
						display: 'flex',
						justifyContent: {
							xs: 'center',
							sm: 'center',
							md: 'center',
							lg: 'center',
							xl: 'flex-start',
						},
						alignItems: {
							xs: 'center',
							sm: 'center',
							md: 'center',
							lg: 'center',
							xl: 'flex-end',
						},
						width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: 'auto' },
					}}
				>
					<Typography variant="caption" sx={{ color: 'white',}}>
						{appVersion.portal}
					</Typography>
				</Box>
			</MainWrapper>
		</Box>
	)
}

export default Services
