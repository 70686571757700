import { useContext } from 'react'
import { Box, Button, SvgIcon, Typography } from '@mui/material'
import { green, grey } from '@mui/material/colors'
import { ReactComponent as soporteIcon } from '../../images/icons/Soporte.svg'
import { LanguageContext } from '../../../context/languageContext'

const SupportCardButton = ({ subject = '' }) => {
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	return (
		<Box
			sx={{
				backgroundColor: green[50],
			}}
		>
			<Button
				variant="text"
				sx={{
					color: grey[900],
					height: 100,
					'&:hover': {
						backgroundColor: green[100],
					},
				}}
				href={`mailto:soporte@ludusglobal.com?subject=${encodeURIComponent(
					subject
				)}`}
				fullWidth
			>
				<SvgIcon
					sx={{
						fontSize: '8vh',
						ml: 2,
					}}
					component={soporteIcon}
					color="secondary"
				/>
				<Typography
					sx={{
						fontSize: {
							xs: 10,
							sm: 12,
							md: 14,
							lg: 16,
							xl: 16,
						},
						fontWeight: 200,
						mx: 3,
					}}
					variant="inherit"
					align="left"
				>
					{`${translate('button_contact_support_pt1')} `}
					{`${translate('button_contact_support_pt2')}`}
					<strong> soporte@ludusglobal.com</strong>
				</Typography>
			</Button>
		</Box>
	)
}

export default SupportCardButton
