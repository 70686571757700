import { useContext, useState, MouseEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	Typography,
	Divider,
	SvgIcon,
	OutlinedInput,
	IconButton,
	Card,
	InputAdornment,
	CardActions,
	CardContent,
	CardMedia,
} from '@mui/material'
import grey from '@mui/material/colors/grey'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { ReactComponent as passwordIcon } from '../../images/icons/Password.svg'
import LogoSmall from '../../images/imagotipo.png'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import { LanguageContext } from '../../../context/languageContext'
import UserResetPass from '../../services/userResetPass'
import { GradientButton } from '../buttons/GradientButton'
import SupportCardButton from '../buttons/SupportCardButton'

type ResetPassFormProps = {
	user: {
		id: number
		email: string
		firstName: string
		lastName: string
		role: string
		verified: boolean
		verificationCode: string
	}
	resetCode: string
}

type FormValues = {
	password: string
	passwordConfirmation: string
}

function ResetPassForm(props: ResetPassFormProps) {
	const { user, resetCode } = props

	const [showPassword, setShowPassword] = useState(false)

	const { addError } = useErrorMessageContext()

	const { addSuccess } = useSuccessMessageContext()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, submitCount },
	} = useForm<FormValues>({ delayError: 1000, mode: 'onChange' })

	let navigate = useNavigate()

	const onSubmit = handleSubmit(async (request: FormValues) => {
		const { password, passwordConfirmation } = request

		const userDataRequest = {
			userId: user.id,
			userEmail: user.email,
			resetCode,
			password,
			passwordConfirmation,
		}

		const { status, code } = await UserResetPass(userDataRequest)

		if (status !== 200) {
			return addError(`${translate(code)}`)
		} else {
			addSuccess(`${translate('success_change_pass')}`)
			setTimeout(() => {
				navigate('../')
				return window.location.reload()
			}, 3000)
		}
	})

	return (
		<Card
			sx={{
				minWidth: { xs: 310, sm: 340 },
				maxWidth: 640,
				borderRadius: '15px',
				boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
			}}
		>
			<CardContent sx={{ mx: 3 }}>
				<CardMedia
					component="img"
					image={`${LogoSmall}`}
					title="LogoSmall"
					sx={{
						margin: 'auto',
						display: 'block',
						width: 'auto',
						maxHeight: '100px',
					}}
				/>
				<Typography
					variant="h5"
					align="center"
					color="text.secondary"
					fontSize={{
						xs: 20,
						sm: 24,
						md: 24,
						lg: 24,
						xl: 24
					}}>
					<strong>{`${translate('title_welcome')}`}</strong>
				</Typography>
				<Divider variant="middle" sx={{ mx: 20 }} />
				<Typography
					variant="h6"
					align="center"
					sx={{ mb: 3 }}
					color="text.secondary"
				>
					{user !== undefined
						? `${translate('title_change_pass_welcome_pt1')} ${user.firstName
						}, ${translate('title_change_pass_welcome_pt2')}`
						: submitCount !== 0 && `${translate('title_change_pass_try_again')}`}
				</Typography>

				<OutlinedInput
					{...register('password', {
						required: {
							value: true,
							message: `${translate('mistake_empty_pass_field')}`,
						},
						maxLength: {
							value: 20,
							message: `${translate('mistake_long_pass_field')}`,
						},
						minLength: {
							value: 6,
							message: `${translate('mistake_short_pass_field')}`,
						},
						pattern: {
							value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
							message: `${translate('mistake_unsafe_pass_field')}`,
						},
					})}
					aria-label={`${translate('label_password')}`}
					fullWidth
					placeholder={`${translate('label_password')}`}
					type={showPassword ? 'text' : 'password'}
					sx={{
						mt: 1,
						border: errors?.password?.type === 'pattern' ? 'solid red 4px' : null,
						borderRadius: '50px',
						backgroundColor: grey[200],
						'& .MuiOutlinedInput-input': {
							color: grey[700],
							'&:hover fieldset': {
								borderColor: 'green',
							},
						},
					}}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword((show) => !show)}
								onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
									event.preventDefault()
								}}
								edge="end"
								sx={{
									color: grey[500],
									mr: '1px',
								}}
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					startAdornment={
						<InputAdornment position="start">
							<SvgIcon component={passwordIcon} color="secondary" />
						</InputAdornment>
					}
				/>
				{errors?.password && (
					<Typography
						variant="caption"
						align="center"
						color="red"
						sx={{ ml: 3, fontFamily: 'Inter' }}
					>
						{errors.password.message}
					</Typography>
				)}

				<OutlinedInput
					{...register('passwordConfirmation', {
						required: {
							value: true,
							message: `${translate('mistake_empty_change_pass_field')}`,
						},
						validate: (value) => value === watch('password'),
					})}
					aria-label={`${translate('label_confirm_pass')}`}
					fullWidth
					placeholder={`${translate('label_confirm_pass')}`}
					type={showPassword ? 'text' : 'password'}
					sx={{
						mt: 1,
						border:
							errors?.passwordConfirmation?.type === 'validate'
								? 'solid red 4px'
								: null,
						borderRadius: '50px',
						backgroundColor: grey[200],
						'& .MuiOutlinedInput-input': {
							color: grey[700],
							'&:hover fieldset': {
								borderColor: 'green',
							},
						},
					}}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword((show) => !show)}
								onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
									event.preventDefault()
								}}
								edge="end"
								sx={{
									color: grey[500],
									mr: '1px',
								}}
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					startAdornment={
						<InputAdornment position="start">
							<SvgIcon component={passwordIcon} color="secondary" />
						</InputAdornment>
					}
				/>
				{errors?.passwordConfirmation?.message && (
					<Typography
						variant="caption"
						align="center"
						color="red"
						sx={{ ml: 3, fontFamily: 'Inter' }}
					>
						{errors.passwordConfirmation.message}
					</Typography>
				)}
				{errors?.passwordConfirmation?.type === 'validate' && (
					<Typography
						variant="caption"
						align="center"
						color="red"
						sx={{ ml: 3, fontFamily: 'Inter' }}
					>
						{`${translate('mistake_not_match_change_pass_field')}`}
					</Typography>
				)}
			</CardContent>
			<CardActions sx={{ justifyContent: 'flex-start', mx: 4, mb: 4 }}>
				<GradientButton
					fullWidth
					sx={{ color: 'white', padding: '14px 16px' }}
					variant="contained"
					onClick={onSubmit}
				>
					{`${translate('button_send_change_pass')}`}
				</GradientButton>
			</CardActions>
			{submitCount !== 0 && <SupportCardButton />}
		</Card>
	)
}

export default ResetPassForm
