import axios from 'axios'

export async function userVerifyLRSAccount(email: string) {
	return await axios
		.post(
			`${process.env.REACT_APP_AUTH_API_URL}/api/lrs/verifyAccount`,
			{
				email: email,
			},
			{
				headers: {
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					'x-api-version': process.env.REACT_APP_LOGIN_API_VERSION
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			console.error(error)
			return error.response.data
		})
}
