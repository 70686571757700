import axios from 'axios'

export type ConfirmEmailUserRequest = {
	oldEmail: string
	newEmail: string
}

export async function userChageEmailRequest(request: ConfirmEmailUserRequest, apiVersion: string) {
	const { oldEmail, newEmail } = request

	return await axios
		.post(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/changeEmail`,
			{
				oldEmail: oldEmail,
				newEmail: newEmail,
			},
			{
				headers: {
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					'x-api-version': `${apiVersion}`
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			return error.response.data
		})
}

export const userChangeEmailConfirmation = async (
	id: string | undefined,
	emailChangeCode: string | undefined
) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/verifyEmail/${id}/${emailChangeCode}`,
			{
				headers: {
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
				},
			}
		)

		return response.data
	} catch (error) {
		return error.response.data
	}
}
