import { useContext, useEffect, useState, MouseEvent } from 'react'
import { useForm } from 'react-hook-form'
import { Box, IconButton, TextField } from '@mui/material'
import grey from '@mui/material/colors/grey'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { useAuthContext } from '../../../context/authContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import { LanguageContext } from '../../../context/languageContext'
import { userChagePasswordRequest } from '../../services/userChangePassword'
import { GradientButton } from '../buttons/GradientButton'

type FormValues = {
	originalPassword: string
	newPassword: string
	confirmPassword: string
}

type ProfilePasswordFormProps = {
	onClose: () => void;
}

const ProfilePasswordForm = (props: ProfilePasswordFormProps) => {
	const { user } = useAuthContext()
	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const [showOriginalPassword, setShowOriginalPassword] = useState(false)
	const [showNewPassword, setShowNewPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)
	const {
		register,
		setValue,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			originalPassword: '',
			newPassword: '',
			confirmPassword: '',
		},
		delayError: 500,
		mode: 'onChange',
	})

	useEffect(() => {
		setValue('originalPassword', '', {
			shouldValidate: false,
			shouldDirty: false,
		})
	}, [user, setValue])

	const onSubmit = handleSubmit(async (request: FormValues) => {
		const { originalPassword, newPassword, confirmPassword } = request
		if (!newPassword) return
		if (confirmPassword !== newPassword) {
			return
		}
		props.onClose()
		const { status, code } = await userChagePasswordRequest({
			email: user.email,
			oldPassword: originalPassword,
			newPassword: newPassword,
			confirmPassword: confirmPassword,
		}, user.deploymentGroup.api)

		if (status !== 200) {
			addError(`${translate(code)}`)
		} else {
			addSuccess(`${translate('success_profile_change_password_form')}`)
		}
	})

	return (
		<Box
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
				display: 'flex',
				flexDirection: 'column',
				flexWrap: 'nowrap',
				justifyContent: 'center',
				alignItems: 'center',
				alignContent: 'stretch',
				py: '2vh',
			}}
		>
			<TextField
				{...register('originalPassword', {
					required: {
						value: true,
						message: `${translate('mistake_empty_pass_field')}`,
					},
					pattern: {
						value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
						message: `${translate('mistake_invalid_pass_field')}`,
					},
				})}
				type={showOriginalPassword ? 'text' : 'password'}
				error={errors?.originalPassword ? true : false}
				aria-label={`${translate('label_old_password')}`}
				sx={{
					width: '100%',
					height: '80px',
					'.MuiFormHelperText-root': {
						fontFamily: 'Inter',
					},
					'.MuiInputLabel-root': {
						color: '#757575 !important',
					},
				}}
				label={`${translate('label_old_password')}`}
				helperText={
					errors?.originalPassword ? errors?.originalPassword.message : ''
				}
				variant="standard"
				color="primary"
				focused
				InputProps={{
					style: { fontSize: 14 },
					endAdornment: (
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowOriginalPassword((show) => !show)}
							onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
								event.preventDefault()
							}}
							edge="end"
							sx={{
								color: grey[500],
								mr: '1px',
							}}
						>
							{showOriginalPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					),
				}}
				onFocus={() =>
					setValue('originalPassword', '', {
						shouldValidate: false,
						shouldDirty: false,
					})
				}
			/>

			<TextField
				{...register('newPassword', {
					required: {
						value: true,
						message: `${translate('mistake_empty_pass_field')}`,
					},
					maxLength: {
						value: 20,
						message: `${translate('mistake_long_pass_field')}`,
					},
					minLength: {
						value: 6,
						message: `${translate('mistake_short_pass_field')}`,
					},
					pattern: {
						value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
						message: `${translate('mistake_unsafe_pass_field')}`,
					},
				})}
				type={showNewPassword ? 'text' : 'password'}
				error={errors?.newPassword ? true : false}
				aria-label={`${translate('label_new_password')}`}
				sx={{
					width: '100%',
					height: '100px',
					'.MuiFormHelperText-root': {
						fontFamily: 'Inter',
					},
					'.MuiInputLabel-root': {
						color: '#757575 !important',
					},
				}}
				label={`${translate('label_new_password')}`}
				defaultValue="          "
				helperText={errors?.newPassword ? errors?.newPassword.message : ''}
				variant="standard"
				color="primary"
				focused
				InputProps={{
					style: { fontSize: 14 },
					endAdornment: (
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowNewPassword((show) => !show)}
							onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
								event.preventDefault()
							}}
							edge="end"
							sx={{
								color: grey[500],
								mr: '1px',
							}}
						>
							{showNewPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					),
				}}
			/>

			<TextField
				{...register('confirmPassword', {
					required: {
						value: true,
						message: `${translate('mistake_empty_confirm_pass_field')}`,
					},
					validate: (value) => value === watch('newPassword'),
				})}
				type={showConfirmPassword ? 'text' : 'password'}
				error={errors?.confirmPassword ? true : false}
				aria-label={`${translate('label_confirm_new_pass')}`}
				sx={{
					width: '100%',
					height: '80px',
					'.MuiFormHelperText-root': {
						fontFamily: 'Inter',
					},
					'.MuiInputLabel-root': {
						color: '#757575 !important',
					},
				}}
				label={`${translate('label_confirm_new_pass')}`}
				defaultValue="          "
				helperText={
					errors?.confirmPassword
						? errors?.confirmPassword.type === 'validate'
							? translate('mistake_not_match_confirm_pass_field')
							: errors?.confirmPassword.message
						: ''
				}
				variant="standard"
				color="primary"
				focused
				InputProps={{
					style: { fontSize: 14 },
					endAdornment: (
						<IconButton
							aria-label="toggle password visibility"
							onClick={() => setShowConfirmPassword((show) => !show)}
							onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
								event.preventDefault()
							}}
							edge="end"
							sx={{
								color: grey[500],
								mr: '1px',
							}}
						>
							{showConfirmPassword ? <VisibilityOff /> : <Visibility />}
						</IconButton>
					),
				}}
			/>
			<GradientButton
				sx={{ color: 'white', padding: '14px 16px', mt: 2 }}
				variant="contained"
				onClick={onSubmit}
			>
				{translate('button_change_profile_save_form')}
			</GradientButton>
		</Box>
	)
}

export default ProfilePasswordForm
