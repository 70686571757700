import chargeBeeLogin from '../services/chargeBeeLogin'

const useChargeBeeLoginLink = (status, user, addError, translate) => {
	const fetchLoginLink = async () => {
		if (status.connection !== 'online') {
			addError(`${translate('error_connection')}`)
			return
		}

		const loginRequest = await chargeBeeLogin(
			user.email,
			user.deploymentGroup.api
		)

		if (loginRequest.status !== 200) {
			addError(`${translate(loginRequest.code)}`)
		} else {
			return loginRequest.data.portal_session.access_url
		}
	}

	return { fetchLoginLink }
}

export default useChargeBeeLoginLink
