import { FC, useContext } from 'react'
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import Avatar from 'react-avatar'
import { customHeaderStyles } from '../../ludusTheme.d'
import LogoSmall from '../../images/logo-Ludus-negro.png'
import { LanguageContext } from '../../../context/languageContext'
import { useAuthContext } from '../../../context/authContext'
import WifiOffRoundedIcon from '@mui/icons-material/WifiOffRounded'
import { useStatusContext } from '../../../context/statusContext'
import LanguageSelectorModal from '../../components/modals/languageSelectorModal'


type HeaderProps = {
	handleClick: () => void
}

const Header: FC<HeaderProps> = ({ handleClick }) => {
	const { status } = useStatusContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const { user } = useAuthContext()

	const connectionWarning = () => {
		if (status.connection !== 'online') {
			return (
				<Button
					variant="text"
					endIcon={<WifiOffRoundedIcon />}
					sx={{ color: 'red', cursor: 'auto', pointerEvents: 'none' }}
				>
					{translate('label_connection')}
				</Button>
			)
		}
	}

	return (
		<>
			<AppBar
				position="fixed"
				color="secondary"
				sx={{ backgroundColor: 'white', paddingX: { xs: 0, sm: 0, md: '5%' } }}
			>
				<Toolbar>
					<Box sx={customHeaderStyles.navBarTittle}>
						<Box
							component="img"
							src={`${LogoSmall}`}
							title="LogoSmall"
							sx={{
								margin: 0,
								display: 'block',
								width: 'auto',
								maxWidth: { xs: 80, sm: 100, md: 160, lg: 160, xl: 160 },
							}}
						/>
					</Box>
					{connectionWarning()}
					<Box
						sx={{
							flexGrow: { xs: 1, sm: 1, md: 0, lg: 0, xl: 0 },
							display: 'flex',
							flexDirection: 'row',
							flexWrap: 'nowrap',
							justifyContent: 'space-between',
							alignItems: 'center',
							alignContent: 'stretch',
							gap: { xs: 0, sm: 0, md: 2, lg: 5, xl: 5 },
						}}
					>
						<LanguageSelectorModal />
						<Box>
							<Button
								variant="text"
								startIcon={
									<Avatar
										name={
											user.firstName && user.lastName
												? `${user.firstName} ${user.lastName}`
												: 'User'
										}
										size="30"
										alt="avatar"
										aria-label="avatar"
										color={green['A100']}
										fgColor="black"
										round="100px"
									/>
								}
								onClick={handleClick}
							>
								<Typography
									variant="body2"
									color="black"
									fontFamily={'Barlow Condensed'}
									sx={{
										fontSize: '22px',
										fontWeight: 600,
										display: {
											xs: 'none',
											sm: 'none',
											md: 'block',
											lg: 'block',
											xl: 'block',
										},
									}}
								>
									{translate('button_my_profile')}
								</Typography>
							</Button>
						</Box>
					</Box>
				</Toolbar>
			</AppBar>
			<div style={customHeaderStyles.offset}></div>
		</>
	)
}

export default Header
