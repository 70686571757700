import { useContext, MouseEvent } from 'react'
import { Box, TextField, Button, Typography } from '@mui/material'
import { LanguageContext } from '../../../context/languageContext'
import { useAuthContext } from '../../../context/authContext'

type FormProps = {
	handleClick: (event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void
}

export default function ProfileForm({ handleClick }: FormProps) {
	const { user } = useAuthContext()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	return (
		<Box>
			<TextField
				aria-label={`${translate('label_email')}`}
				sx={{
					pointerEvents: 'none !important',
					width: '100%',
					height: '80px',
					'.MuiInputLabel-root': {
						color: '#757575 !important',
					},
				}}
				label={`${translate('label_email')}`}
				defaultValue={user.email ? user.email : 'Email'}
				variant="standard"
				color="primary"
				focused
				InputProps={{
					readOnly: true,
					style: { fontSize: 14 },
					endAdornment: (
						<Button
							variant="text"
							style={{ pointerEvents: 'auto', width: '12vh' }}
							onClick={(event) => handleClick(event)}
							id="emailForm"
						>
							<Typography
								variant="body2"
								color="#757575"
								sx={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									'&:hover': {
										backgroundColor: 'white',
										overflow: 'visible',
										whiteSpace: 'normal',
										height: 'auto',
									},
								}}
							>
								{`${translate('button_change_profile_form')}`}
							</Typography>
						</Button>
					),
				}}
			/>
			<TextField
				type="password"
				aria-label={`${translate('label_password')}`}
				sx={{
					pointerEvents: 'none !important',
					width: '100%',
					height: '80px',
					'.MuiInputLabel-root': {
						color: '#757575 !important',
					},
				}}
				label={`${translate('label_password')}`}
				defaultValue="          "
				variant="standard"
				color="primary"
				focused
				InputProps={{
					readOnly: true,
					style: { fontSize: 14 },
					endAdornment: (
						<Button
							variant="text"
							style={{ pointerEvents: 'auto', width: '12vh' }}
							onClick={(event) => handleClick(event)}
							id="passwordForm"
						>
							<Typography
								variant="body2"
								color="#757575"
								sx={{
									whiteSpace: 'nowrap',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									'&:hover': {
										overflow: 'visible',
										whiteSpace: 'normal',
										height: 'auto',
									},
								}}
							>
								{translate('button_change_profile_form')}
							</Typography>
						</Button>
					),
				}}
			/>
			{/* <TextField
				aria-label={`${translate('label_name_profile_form')}`}
				sx={{
					width: '100%',
					height: '80px',
					'.MuiInputLabel-root': {
						color: '#757575 !important',
					},
				}}
				label={`${translate('label_name_profile_form')}`}
				defaultValue={
					user.firstName && user.lastName
						? `${user.firstName} ${user.lastName}`
						: 'Name'
				}
				variant="standard"
				color="primary"
				focused
				InputProps={{
					readOnly: true,
					style: { fontSize: 14 },
					endAdornment: (
						<Button
							variant="text"
							onClick={(event) => handleClick(event)}
							id="nameForm"
						>
							<Typography variant="body2" color="#757575">
								{`${translate('button_change_profile_form')}`}
							</Typography>
						</Button>
					),
				}}
			/> */}
		</Box>
	)
}
