import { useReducer, createContext, FC, ReactNode } from 'react'
import en from '../common/i18n/en.json'
import es from '../common/i18n/es.json'
import ca from '../common/i18n/ca.json'
import de from '../common/i18n/de.json'
import es_lt from '../common/i18n/es-lt.json'
import eu from '../common/i18n/eu.json'
import fr from '../common/i18n/fr.json'
import it from '../common/i18n/it.json'
import ms from '../common/i18n/ms.json'
import nl from '../common/i18n/nl.json'
import pl from '../common/i18n/pl.json'
import pt from '../common/i18n/pt.json'
import pt_br from '../common/i18n/pt-br.json'
import test from '../common/i18n/test.json'

import tr from '../common/i18n/tr.json'
import { useNavigate } from 'react-router-dom'
enum LangActionType {
	SET_LANGUAGE = 'SET_LANGUAGE',
}

type LangState = {
	language: string
}

type LangStateProps = {
	children: ReactNode
}

type SetLanguageAction = {
	type: typeof LangActionType.SET_LANGUAGE
	payload: string
}

type ContextProps = {
	state: LangState
	dispatch: {
		setLanguage: (lang: string) => void
		translate: (key: string) => string
	}
}

const langReducer = (
	state: LangState,
	action: SetLanguageAction
): LangState => {
	switch (action.type) {
		case LangActionType.SET_LANGUAGE:
			return {
				language: action.payload,
			}
		default:
			return state
	}
}

const localStorageLanguage = localStorage.getItem('language')
const initialState = {
	language: localStorageLanguage ? localStorageLanguage : 'es',
}

export const LanguageContext = createContext({} as ContextProps)

const LanguageContextProvider: FC<LangStateProps> = ({ children }) => {
	const [state, dispatch] = useReducer(langReducer, initialState)

	const setLanguage = (lang: string) => {
		localStorage.setItem('language', lang);
		dispatch({
			type: LangActionType.SET_LANGUAGE,
			payload: lang,
		});
		const currentPath = window.location.href.split('?');
		currentPath[1] = lang;
		const newPath = currentPath[0];
		const newUrl = `${newPath}?${lang}`;
		window.history.replaceState(null, '', newUrl);
	}


	const translate = (key: string): string => {
		const { language } = state
		let languageData: { [key: string]: string } = {}

		switch (language) {
			case 'es':
				languageData = es
				break
			case 'es-lt':
				languageData = es_lt
				break
			case 'eu':
				languageData = eu
				break
			case 'en':
				languageData = en
				break
			case 'ca':
				languageData = ca
				break
			case 'de':
				languageData = de
				break
			case 'fr':
				languageData = fr
				break
			case 'it':
				languageData = it
				break
			case 'ms':
				languageData = ms
				break
			case 'nl':
				languageData = nl
				break
			case 'pl':
				languageData = pl
				break
			case 'pt':
				languageData = pt
				break
			case 'pt-br':
				languageData = pt_br
				break
			case 'test':
				languageData = test
				break
			case 'ids':
				const ids = { ...test }
				const keys = Object.keys(ids)
				keys.forEach((key) => {
					ids[key] = key
				})
				languageData = ids
				break
			case 'tr':
				languageData = tr
				break
			case 'eu':
				languageData = eu
				break

			default:
				break
		}

		return languageData[key]
	}

	return (
		<LanguageContext.Provider
			value={{ state, dispatch: { setLanguage, translate } }}
		>
			{children}
		</LanguageContext.Provider>
	)
}

export default LanguageContextProvider
