import { FC, useContext, useEffect, useState } from 'react'
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material'
import { green } from '@mui/material/colors'
import Avatar from 'react-avatar'
import { customHeaderStyles } from '../../ludusTheme.d'
import LogoSmall from '../../images/logo-Ludus-negro.png'
import LogoIcono from '../../images/logo.png'
import { LanguageContext } from '../../../context/languageContext'
import { useAuthContext } from '../../../context/authContext'
import WifiOffRoundedIcon from '@mui/icons-material/WifiOffRounded'
import { useStatusContext } from '../../../context/statusContext'
import HelpButton from '../buttons/HelpButton'
import HamburgerMenuButton from '../buttons/HamburgerMenuButton'
import LanguageSelectorModal from '../modals/languageSelectorModal'


type HeaderProps = {
	handleClick: () => void
	handleMenuClick: () => void
}

const Header: FC<HeaderProps> = ({ handleClick, handleMenuClick }) => {
	const { status } = useStatusContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const [pageTitle, setPageTitle] = useState('')
	const { user } = useAuthContext()

	const connectionWarning = () => {
		if (status.connection !== 'online') {
			return (
				<Button
					variant="text"
					endIcon={<WifiOffRoundedIcon />}
					sx={{ color: 'red', cursor: 'auto', pointerEvents: 'none' }}
				>
					{translate('label_connection')}
				</Button>
			)
		}
	}

	useEffect(() => {
		const handleLocationChange = () => {
			if (window.location.href.includes('statistics')) {
				setPageTitle(translate('title_statistics_page'))
			} else {
				setPageTitle('')
			}
		}

		handleLocationChange()

		window.addEventListener('hashchange', handleLocationChange)

		return () => {
			window.removeEventListener('hashchange', handleLocationChange)
		}
	}, [language])

	return (
		<>
			<AppBar
				position="fixed"
				color="secondary"
				sx={{
					backgroundColor: 'white',
					paddingRight: { xs: '0', sm: '0', md: '5%', lg: '5%', xl: '5%' },
					paddingLeft: { xs: '0', sm: '0', md: '5%', lg: '2%', xl: '1%' },
				}}
			>
				<Box sx={{ display: 'flex', alignItems: 'center', }}>
					<HamburgerMenuButton onClick={handleMenuClick} />
					<Toolbar sx={{ ...customHeaderStyles.navBarTittle, paddingLeft: '0' }}>
						<Box sx={{ display: 'flex', alignItems: 'center' }}>
							<Box
								component="img"
								src={LogoSmall}
								title="LogoSmall"
								sx={{
									marginLeft: { xs: "0px", sm: "-15px", md: "35px", lg: "0px", xl: "35px" },
									marginRight: { xs: "0px", sm: "10px", md: "35px", lg: "0px", xl: "35px" },
									display: { xs: 'none', sm: 'none', md: 'none', lg: 'block', xl: 'block' },
									width: 'auto',
									maxWidth: { lg: 140, xl: 160 },
								}}
							/>
							<Box
								component="img"
								src={LogoIcono}
								title="LogoIcono"
								sx={{
									marginLeft: { xs: "0px", sm: "-5px", md: "-5px", lg: "35px", xl: "35px" },
									marginRight: { xs: "0px", sm: "0px", md: "0px", lg: "35px", xl: "35px" },
									display: { xs: 'block', sm: 'block', md: 'block', lg: 'none', xl: 'none' },
									width: 'auto',
									maxWidth: { xs: 50, sm: 60, md: 60 },
								}}
							/>
						</Box>
						{connectionWarning()}
						<Box sx={{ flexGrow: 1, textAlign: 'center' }}>
							{pageTitle && (
								<Typography
									variant="h5"
									sx={{
										fontWeight: 700,
										fontSize: { xs: "0px", sm: "0px", md: "0px", lg: "20px", xl: "28px" },
										marginLeft: "200px"
									}}
								>
									{pageTitle}
								</Typography>
							)}
						</Box>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
								flexWrap: 'nowrap',
								justifyContent: { xs: 'center', sm: 'center', md: 'flex-end', lg: 'flex-end', xl: 'flex-end' },
								alignItems: 'center',
								alignContent: 'stretch',
								gap: { xs: 0, sm: 0, md: 2, lg: 5, xl: 5 },
								width: { xs: '100%', sm: '100%', md: 'auto' },
							}}
						>
							<HelpButton />
							<LanguageSelectorModal />
							<Button
								variant="text"
								startIcon={
									<Avatar
										name={
											user.firstName && user.lastName
												? `${user.firstName} ${user.lastName}`
												: 'User'
										}
										size="30"
										alt="avatar"
										aria-label="avatar"
										color={green['A100']}
										fgColor="black"
										round="100px"
									/>
								}
								onClick={handleClick}
							>
								<Typography
									variant="body2"
									color="black"
									fontFamily={'Barlow Condensed'}
									sx={{
										fontSize: '22px',
										fontWeight: 600,
										display: {
											xs: 'none',
											sm: 'none',
											md: 'none',
											lg: 'block',
											xl: 'block',
										},
									}}
								>
									{translate('button_my_profile')}
								</Typography>
							</Button>
						</Box>
					</Toolbar>
				</Box>
			</AppBar>
			<div style={customHeaderStyles.offset}></div>
		</>
	)
}

export default Header
