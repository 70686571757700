const daysOfWeek = {
	es: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
	'es-lt': [
		'domingo',
		'lunes',
		'martes',
		'miércoles',
		'jueves',
		'viernes',
		'sábado',
	],
	ca: [
		'diumenge',
		'dilluns',
		'dimarts',
		'dimecres',
		'dijous',
		'divendres',
		'dissabte'
	],
	eu: [
		'igandea',
		'astelehena',
		'asteartea',
		'asteazkena',
		'osteguna',
		'ostirala',
		'larunbata'
	],
	en: [
		'Sunday',
		'Monday',
		'Tuesday',
		'Wednesday',
		'Thursday',
		'Friday',
		'Saturday',
	],
	de: [
		'Sonntag',
		'Montag',
		'Dienstag',
		'Mittwoch',
		'Donnerstag',
		'Freitag',
		'Samstag',
	],
	fr: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
	it: [
		'Domenica',
		'Lunedì',
		'Martedì',
		'Mercoledì',
		'Giovedì',
		'Venerdì',
		'Sabato',
	],
	ms: ['Ahad', 'Isnin', 'Selasa', 'Rabu', 'Khamis', 'Jumaat', 'Sabtu'],
	nl: [
		'Zondag',
		'Maandag',
		'Dinsdag',
		'Woensdag',
		'Donderdag',
		'Vrijdag',
		'Zaterdag',
	],
	pl: [
		'Niedziela',
		'Poniedziałek',
		'Wtorek',
		'Środa',
		'Czwartek',
		'Piątek',
		'Sobota',
	],
	pt: [
		'Domingo',
		'Segunda-feira',
		'Terça-feira',
		'Quarta-feira',
		'Quinta-feira',
		'Sexta-feira',
		'Sábado',
	],
	'pt-br': [
		'Domingo',
		'Segunda-feira',
		'Terça-feira',
		'Quarta-feira',
		'Quinta-feira',
		'Sexta-feira',
		'Sábado',
	],
	tr: [
		'Pazar',
		'Pazartesi',
		'Salı',
		'Çarşamba',
		'Perşembe',
		'Cuma',
		'Cumartesi',
	],
}
const months = {
	es: [
		'enero',
		'febrero',
		'marzo',
		'abril',
		'mayo',
		'junio',
		'julio',
		'agosto',
		'septiembre',
		'octubre',
		'noviembre',
		'diciembre',
	],
	'es-lt': [
		'enero',
		'febrero',
		'marzo',
		'abril',
		'mayo',
		'junio',
		'julio',
		'agosto',
		'septiembre',
		'octubre',
		'noviembre',
		'diciembre',
	],
	ca: [
		'gener',
		'febrer',
		'març',
		'abril',
		'maig',
		'juny',
		'juliol',
		'agost',
		'setembre',
		'octubre',
		'novembre',
		'desembre'
	],
	eu: [
		'urtarrila',
		'otsaila',
		'martxoa',
		'apirila',
		'maiatza',
		'ekaina',
		'uztaila',
		'abuztua',
		'iraila',
		'urria',
		'azaroa',
		'abendua'
	],
	en: [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	],
	de: [
		'Januar',
		'Februar',
		'März',
		'April',
		'Mai',
		'Juni',
		'Juli',
		'August',
		'September',
		'Oktober',
		'November',
		'Dezember',
	],
	fr: [
		'janvier',
		'février',
		'mars',
		'avril',
		'mai',
		'juin',
		'juillet',
		'août',
		'septembre',
		'octobre',
		'novembre',
		'décembre',
	],
	it: [
		'gennaio',
		'febbraio',
		'marzo',
		'aprile',
		'maggio',
		'giugno',
		'luglio',
		'agosto',
		'settembre',
		'ottobre',
		'novembre',
		'dicembre',
	],
	ms: [
		'Januari',
		'Februari',
		'Mac',
		'April',
		'Mei',
		'Jun',
		'Julai',
		'Ogos',
		'September',
		'Oktober',
		'November',
		'Disember',
	],
	nl: [
		'januari',
		'februari',
		'maart',
		'april',
		'mei',
		'juni',
		'juli',
		'augustus',
		'september',
		'oktober',
		'november',
		'december',
	],
	pl: [
		'styczeń',
		'luty',
		'marzec',
		'kwiecień',
		'maj',
		'czerwiec',
		'lipiec',
		'sierpień',
		'wrzesień',
		'październik',
		'listopad',
		'grudzień',
	],
	pt: [
		'janeiro',
		'fevereiro',
		'março',
		'abril',
		'maio',
		'junho',
		'julho',
		'agosto',
		'setembro',
		'outubro',
		'novembro',
		'dezembro',
	],
	'pt-br': [
		'janeiro',
		'fevereiro',
		'março',
		'abril',
		'maio',
		'junho',
		'julho',
		'agosto',
		'setembro',
		'outubro',
		'novembro',
		'dezembro',
	],
	tr: [
		'Ocak',
		'Şubat',
		'Mart',
		'Nisan',
		'Mayıs',
		'Haziran',
		'Temmuz',
		'Ağustos',
		'Eylül',
		'Ekim',
		'Kasım',
		'Aralık',
	],
}
export function underMaintenance(language: string) {
	return {
		maintenance: validateMaintenance(),
		formattedDate: getformattedDate(language.toLowerCase()),
	}
}

function validateMaintenance() {
	const maintenanceDate = process.env.REACT_APP_MAINTENANCE_ENDING

	const dateParts = maintenanceDate.split('/')

	if (dateParts.length !== 3) return false

	const day = parseInt(dateParts[0], 10)
	const month = parseInt(dateParts[1], 10) - 1
	const year = parseInt(dateParts[2], 10)

	if (isNaN(day) || isNaN(month) || isNaN(year)) return false

	const today = Date.now()
	const inputDate = new Date(year, month, day).getTime()

	return inputDate > today
}
function getformattedDate(language: string) {
	const maintenanceDate = process.env.REACT_APP_MAINTENANCE_BEGINING

	const dateParts = maintenanceDate.split('/')

	if (dateParts.length !== 3) return ''

	const day = parseInt(dateParts[0], 10)
	const month = parseInt(dateParts[1], 10) - 1
	const year = parseInt(dateParts[2], 10)

	if (isNaN(day) || isNaN(month) || isNaN(year)) return ''

	const weekday = new Date(year, month, day).getDay()
	const today = Date.now()
	const inputDate = new Date(year, month, day).getTime()

	const formattedDate = {
		es: `${daysOfWeek[language][weekday]} ${day} de ${months[language][month]}`,
		'es-lt': `${daysOfWeek[language][weekday]} ${day} de ${months[language][month]}`,
		ca: `${daysOfWeek[language][weekday]} ${day} de ${months[language][month]}`,
		eu: `${daysOfWeek[language][weekday]}, ${months[language][month]}ko ${day}a`,
		en: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		de: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		fr: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		it: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		ms: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		nl: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		pl: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		pt: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		'pt-br': `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
		tr: `${daysOfWeek[language][weekday]}, ${months[language][month]} ${day}`,
	}

	return formattedDate[language]
}
