import { useForm, useFormContext } from 'react-hook-form'
import { drawerWidth, drawerWidthTablet } from '../../ludusTheme.d'
import {
	Box,
	Drawer,
	Typography,
	drawerClasses,
	IconButton,
} from '@mui/material'
import ContactSupportForm from '../forms/ContactSupportForm'
import suportButton from '../../images/boton-soporte.png'
import { LanguageContext } from '../../../context/languageContext'
import { useContext } from 'react'
import CloseIcon from '@mui/icons-material/Close'
import contactSupportEmail from '../../services/contactSupport'
import { useAuthContext } from '../../../context/authContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'

type FormValues = {
	name: string
	phone: string
	text: string
}

type ContactSupportBarProps = {
	open: boolean
	onClose: () => void
}

const ContactSupportBar = (props: ContactSupportBarProps) => {
	const methods = useFormContext()
	const { open, onClose } = props
	const { user } = useAuthContext()
	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const {
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			name: '',
			phone: '',
			text: '',
		},
		delayError: 500,
		mode: 'onChange',
	})

	const onSubmit = handleSubmit(async (request: FormValues) => {
		const { name, phone, text } = request
		const email = user.email
		const { status, code } = await contactSupportEmail(
			{
				email,
				name,
				phone,
				text,
			},
			user.deploymentGroup.api
		)

		if (status !== 200) {
			addError(`${translate(code)}`)
		} else {
			addSuccess(`${translate('success_contact_support_form')}`)
		}
	})
	return (
		<>
			<Drawer
				sx={{
					flexShrink: 0,
					[`& .${drawerClasses.paper}`]: {
						width: {
							xs: '100%',
							sm: '100%',
							md: drawerWidthTablet,
							lg: drawerWidth,
							xl: drawerWidth,
						},
						height: '100%',
						overflowX: 'hidden',
					},
				}}
				variant={'temporary'}
				anchor="right"
				open={open}
				onClose={props.onClose ? props.onClose : null}
			>
				<IconButton
					aria-label="delete"
					color="primary"
					sx={{
						position: 'absolute',
						zIndex: 10,
					}}
					onClick={onClose}
					id="profileForm"
				>
					<CloseIcon sx={{ fontSize: 45 }} />
				</IconButton>

				<Box
					sx={{
						width: '100%',
						bgcolor: 'background.paper',
						marginLeft: 0,
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'nowrap',
						justifyContent: 'center',
						alignItems: 'center',
						alignContent: 'stretch',
						pt: '15vh',
					}}
				>
					<Box
						component="img"
						src={`${suportButton}`}
						title="SuportButton"
						sx={{
							display: 'block',
							width: '100px',
							maxHeight: '12vh',
						}}
					/>
					<Box>
						<Typography
							variant="h5"
							align="center"
							sx={{ mt: 1, fontWeight: 600 }}
							color="black"
						>
							{translate('title_contact_support')}
						</Typography>
						<Typography
							variant="body2"
							align="center"
							sx={{ mt: 1, mx: 3 }}
							color="text.secondary"
						>
							{translate('text_support_form')}
						</Typography>
					</Box>
				</Box>

				<Box
					sx={{
						width: '100%',
						height: '100%',

						bgcolor: 'background.paper',
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'nowrap',
						justifyContent: 'space-between',
						alignItems: 'stretch',
						alignContent: 'stretch',
					}}
				>
					<ContactSupportForm {...methods} onClose={props.onClose} />
				</Box>
			</Drawer>
		</>
	)
}

export default ContactSupportBar
