import MainWrapper from '../components/common/MainWrapper'
import IframeCard from '../components/cards/IframeCard'
import { signJWE } from '../utils/JWTE'
import { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'

const Statistics = () => {
	return (
		<Grid container direction="column" sx={{ flexGrow: 1, minHeight: '100vh' }}>
			<Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
				<MainWrapper>
					<IframeCard
						name="statistics"
						origin={`${process.env.REACT_APP_STATISTICS_URL}/login`}
						style={{}}
					/>
				</MainWrapper>
			</Box>
		</Grid >
	)
}

export default Statistics
