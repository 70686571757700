import { useContext } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { LanguageContext } from '../../../context/languageContext'

const PrivacyProfileButton = () => {
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	return (
		<Box>
			<Button
				variant="text"
				sx={{
					py: 2,
					px: 4,
					color: grey[600],
					fontSize: 10,
					fontWeight: 300,
					backgroundColor: '#efefef',
					'&.MuiButton-root': {
						borderRadius: '0px',
					},
					'& .MuiSvgIcon-fontSizeMedium': {
						fontSize: 40,
					},
					'&:hover': {
						backgroundColor: '#c9c9c9',
					},
				}}
				href="https://www.ludusglobal.com/politica-de-privacidad"
				target="_blank"
				fullWidth
				startIcon={<LockOutlinedIcon />}
			>
				<Box sx={{ width: '100%' }}>
					<Typography
						variant="h5"
						align="left"
						color="black"
						fontFamily={'Barlow Condensed'}
					>
						<b>{translate('subtitle_privacy_button')}</b>
					</Typography>
					<Typography variant="body2" align="left">
						{`${translate('text_privacy_button_pt1')}`}
						<strong> {`${translate('text_privacy_button_pt2')}`}</strong>?
					</Typography>
				</Box>
			</Button>
		</Box>
	)
}

export default PrivacyProfileButton
