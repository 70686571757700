import { useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import {
	Box,
	FormControl,
	Checkbox,
	FormGroup,
	Link,
	Typography,
} from '@mui/material'
import TextField from '@mui/material/TextField/TextField'
import { useAuthContext } from '../../../context/authContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import { LanguageContext } from '../../../context/languageContext'
import { userChageEmailRequest } from '../../services/userChangeEmail'
import { GradientButton } from '../buttons/GradientButton'
import { green } from '@mui/material/colors'

type ProfileEmailFormProps = {
	onClose: () => void
}

type FormValues = {
	email: string
	conditions: boolean
}

const ProfileEmailForm = (props: ProfileEmailFormProps) => {
	const { user } = useAuthContext()
	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors, submitCount, touchedFields, dirtyFields },
	} = useForm<FormValues>({
		defaultValues: {
			email: '',
			conditions: false,
		},
		delayError: 500,
		mode: 'onChange',
	})

	useEffect(() => {
		setValue('email', user.email ? user.email : 'Email', {
			shouldValidate: false,
			shouldDirty: false,
		})
	}, [user, setValue])

	const onSubmit = handleSubmit(async (request: FormValues) => {
		const { email, conditions } = request
		if (email === user.email) return
		if (!conditions) {
			addError(`${translate('error_privacy_policy_profile_change_email_form')}`)
			return
		}
		props.onClose()
		const { status, data, code } = await userChageEmailRequest(
			{
				oldEmail: user.email,
				newEmail: email,
			},
			user.deploymentGroup.api
		)

		if (status !== 200) {
			addError(`${translate(code)}`)
		} else {
			addSuccess(`${translate('success_profile_change_email_form')}`)
		}
	})

	return (
		<Box
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
				display: 'flex',
				flexDirection: 'column',
				flexWrap: 'nowrap',
				justifyContent: 'center',
				alignItems: 'center',
				alignContent: 'stretch',
				py: '2vh',
			}}
		>
			<TextField
				{...register('email', {
					required: {
						value: true,
						message: `${translate('mistake_empty_email_field')}`,
					},
					pattern: {
						value:
							/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						message: `${translate('mistake_invalid_email_field')}`,
					},
				})}
				error={errors?.email ? true : false}
				aria-label={`${translate('label_email')}`}
				sx={{
					width: '100%',
					height: '80px',
					'.MuiFormHelperText-root': {
						fontFamily: 'Inter',
					},
					'.MuiInputLabel-root': {
						color: '#757575 !important',
					},
				}}
				label={`${translate('label_email')}`}
				defaultValue={user.email ? user.email : 'Email'}
				helperText={errors?.email ? errors?.email.message : ''}
				variant="standard"
				color="primary"
				focused
				InputProps={{
					style: { fontSize: 14 },
				}}
			/>
			{errors?.conditions && (
				<Typography
					variant="caption"
					align="center"
					color="red"
					sx={{ ml: 3, fontFamily: 'Inter' }}
				>
					{errors.conditions.message}
				</Typography>
			)}
			<FormControl component="fieldset">
				<FormGroup
					sx={{
						display: 'flex',
						flexDirection: 'row',
						flexWrap: 'nowrap',
						justifyContent: 'center',
						alignItems: 'center',
						alignContent: 'stretch',
					}}
					aria-label="privacy policy"
				>
					<Box display="flex" alignItems="center" sx={{ mb: 1 }}>
						<Checkbox
							{...register('conditions', {
								required: {
									value: true,
									message: `${translate(
										'error_privacy_policy_profile_change_email_form'
									)}`,
								},
							})}
							size="small"
							sx={{
								pl: 0,
							}}
						/>
						<Link
							href="https://www.ludusglobal.com/politica-de-privacidad"
							target="_blank"
						>
							<Typography
								color="#757575"
								sx={{
									fontSize: 12,
									'&:hover': {
										backgroundColor: green['A100'],
									},
								}}
							>
								{translate('label_privacy_policy_email_profile_form')}
							</Typography>
						</Link>
					</Box>
				</FormGroup>
			</FormControl>
			<GradientButton
				sx={{ color: 'white', padding: '14px 16px', mt: 2 }}
				variant="contained"
				onClick={onSubmit}
			>
				{translate('button_change_profile_save_form')}
			</GradientButton>
		</Box>
	)
}

export default ProfileEmailForm
