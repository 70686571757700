import { Card, CardMedia, SxProps } from '@mui/material'
import { useContext, useEffect, useRef, useState } from 'react'
import { signJWE } from '../../utils/JWTE'
import { LanguageContext } from '../../../context/languageContext'

type IframeCardProps = {
	origin: string
	name: string
	style: SxProps
}

function IframeCard(props: IframeCardProps) {
	const { origin, name, style } = props
	const iframeRef = useRef<HTMLIFrameElement>(null)
	const [accessToken, setAccessToken] = useState<string | null>(null)
	const [iframeURL, setIframeURL] = useState<string | null>(null)
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	useEffect(() => {
		const JWE = async () => {
			setAccessToken(
				await signJWE({
					accessToken: window.sessionStorage.getItem('accessToken'),
				})
			)
		}
		JWE()
	}, [])

	useEffect(() => {
		if (accessToken && accessToken !== '') {
			setIframeURL(`${origin}/${accessToken}?lang:${language}`)
		}
	}, [accessToken])

	return (
		<Card sx={{ ...style, border: 0, width: '100%', height: '100%', flexGrow: 1, background: 'transparent' }}>
			<CardMedia
				loading="lazy"
				ref={iframeRef}
				name={name}
				component="iframe"
				sx={{ border: 0, width: '100%', height: '100%' }}
				src={iframeURL}
			/>
		</Card>
	)
}

export default IframeCard
