import { createContext, useContext, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

const NavigationContext = createContext(null)

export const useNavigation = () => useContext(NavigationContext)

export const NavigationProvider = ({ children }) => {
	const navigate = useNavigate()

	const providerValue = useMemo(
		() => ({
			navigateToError: () => navigate('/errorPage'),
			navigateToServices: (versionPath: string, language: string) => {
				navigate(`/${versionPath}/services?${language}`)
			},
			navigateToHome: (versionPath: string, language: string) => {
				navigate(`/${versionPath}?${language}`)
			},
			navigateToStatistics: (versionPath: string, language: string) => {
				navigate(`/${versionPath}/statistics?${language}`)
			},
		}),
		[navigate]
	)

	return (
		<NavigationContext.Provider value={providerValue}>
			{children}
		</NavigationContext.Provider>
	)
}
