import { Button } from '@mui/material'
import { styled } from '@mui/system'

export const GradientButton = styled(Button)(({ variant }) => ({
	...(variant && {
		background: 'linear-gradient(90deg,#7abe79 0,#2f75b6)',
		'&:hover': {
			background: 'linear-gradient(270deg,#7abe79 0,#2f75b6)',
		},
	}),
}))
