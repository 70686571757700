import { Routes, Route } from 'react-router-dom'
import Login from '../layouts/login'
import Services from '../layouts/services'
import Register from '../layouts/verifyAccount'
import PasswordRecovery from '../layouts/passwordRecovery'
import VerifyEmail from '../layouts/verifyEmail'
import VerifyPassword from '../layouts/verifyPassword'
import NotFound from '../layouts/notFound'
import Signin from '../layouts/signin'
import { underMaintenance } from '../utils/underMaintenance'
import { useContext } from 'react'
import { LanguageContext } from '../../context/languageContext'
import MaintenancePage from '../layouts/maintenancePage'
import ErrorPage from '../layouts/errorPage'
import Statistics from '../layouts/statistics'


function Router() {
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const servicesInMaintenance = (process.env.REACT_APP_SERVICE_MAINTENANCE || '')
	const portalMaintenance = servicesInMaintenance.includes('portal');

	return (
		<Routes>
			{portalMaintenance ? (
				<Route path="*" element={<MaintenancePage />} />
			) : (
				<>
					<Route path="/" element={<Login />}>
						<Route path="/:userNameParam/welcome" element={<Login />} />
						<Route path="/login/:JWEToken/:hubLanguage" element={<Login />} />
					</Route>
					<Route path="/statistics" element={<Statistics />} />
					<Route path="/services" element={<Services />} />
					<Route path="/signin/:userId" element={<Signin />} />
					<Route path="/signup/:token" element={<Register />} />
					<Route path="/forgotpass/:token" element={<PasswordRecovery />} />
					<Route path="/verifyEmail/:token" element={<VerifyEmail />} />
					<Route path="/verifyPassword/:token" element={<VerifyPassword />} />
					<Route path="*" element={<NotFound />} />
					<Route path="/errorPage" element={<ErrorPage />} />
					<Route path="/maintenancePage" element={<MaintenancePage />} />
				</>
			)}
		</Routes>
	)
}

export default Router
