import { useEffect } from 'react'
import useLogout from './useLogout'

const useRemoveCookiesOnClose = (user, removeUser, cookies, removeCookie) => {
	useEffect(() => {
		const removeCookiesOnUnload = () => {
			if (user.email !== '' && !user.rememberMe) {
				window.addEventListener('beforeunload', function (event) {
					useLogout(cookies, removeCookie, removeUser)
				})
			} else {
				window.addEventListener('beforeunload', function (event) {
					Object.keys(cookies).forEach((cookie) => {
						if (cookie !== 'AuthToken') {
							removeCookie(cookie, {
								path: '/',
								maxAge: 2592000,
								domain: 'ludusservers.com',
							})
						}
					})
				})
			}
		}

		removeCookiesOnUnload()
	}, [])
}

export default useRemoveCookiesOnClose
