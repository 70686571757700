import AppRouter from './AppRouter'
import ReactDOM from 'react-dom/client'
import { HashRouter } from 'react-router-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { CookiesProvider } from 'react-cookie'
import { ThemeProvider } from '@mui/system'
import { CssBaseline } from '@mui/material'
import ludusTheme from './common/theme/ludusTheme.d'
import { ErrorMessageContextProvider } from './context/errorMessageContext'
import { SuccessMessageContextProvider } from './context/successMessageContext'
import { AuthContextProvider } from './context/authContext'
import LanguageContextProvider from './context/languageContext'
import { StatusContextProvider } from './context/statusContext'
import { ErrorBoundary } from 'react-error-boundary'
import { NavigationProvider } from './context/navigationContext'
import { ErrorBoundaryComponent } from './common/layouts/ErrorBoundaryComponent'
import VersionProvider from './context/appVersionContext'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
	<HashRouter>
		<NavigationProvider>
			<StatusContextProvider>
				<CookiesProvider>
					<ThemeProvider theme={ludusTheme}>
						<LanguageContextProvider>
							<ErrorMessageContextProvider>
								<SuccessMessageContextProvider>
									<AuthContextProvider>
										<VersionProvider>
											<QueryClientProvider client={queryClient}>
												<CssBaseline />
												<ErrorBoundary FallbackComponent={ErrorBoundaryComponent}>
													<AppRouter />
												</ErrorBoundary>
											</QueryClientProvider>
										</VersionProvider>
									</AuthContextProvider>
								</SuccessMessageContextProvider>
							</ErrorMessageContextProvider>
						</LanguageContextProvider>
					</ThemeProvider>
				</CookiesProvider>
			</StatusContextProvider>
		</NavigationProvider>
	</HashRouter>
)
