import axios from 'axios'

export type SigninUserRequest = {
	id: number
	verificationCode: string
	password: string
	passwordConfirmation: string
}

export async function userVerifySsoAccount(request: SigninUserRequest) {
	const { id, verificationCode, password, passwordConfirmation } = request

	return await axios
		.post(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/verifyAccount`,
			{
				id: id,
				verificationCode: verificationCode,
				password: password,
				passwordConfirmation: passwordConfirmation,
			},
			{
				headers: {
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					'x-api-version': process.env.REACT_APP_LOGIN_API_VERSION
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			return error.response.data
		})
}
