export function testURL(url: string): string {
	if (url === 'https://auth-client-dev.ludusservers.com/') {
		window.location.href = 'https://account.ludusservers.com/'
	}
	if (url.includes('#')) return

	const [protocol, rest] = url.split('://')
	const [domain, ...route] = rest.split('/')

	const newURL = `${protocol}://${domain}/#/` + route.join('/')

	return (window.location.href = newURL)
}
