import axios from 'axios'
import { ApiResponse } from '../types/types.d'

export type ResetPassRequest = {
	userId: number
	userEmail: string
	resetCode: string
	password: string
	passwordConfirmation: string
}


export default async function UserResetPass(
	props: ResetPassRequest
): Promise<ApiResponse> {
	const { userId, resetCode, password, passwordConfirmation } = props

	if (password !== passwordConfirmation)
		return {
			status: 404,
			msg: 'Passwords do not match',
		}

	return await axios
		.post(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/resetpassword/${userId}/${resetCode}`,
			{ password: password },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
					'x-api-version': process.env.REACT_APP_LOGIN_API_VERSION
				},
			}
		)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			return error.response.data
		})
}
