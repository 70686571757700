import Button from '@mui/material/Button'

function ModeWarning() {
	if (process.env.REACT_APP_MODE === 'development') {
		return (
			<Button
				variant="outlined"
				sx={{
					color: 'red',
					cursor: 'auto',
					pointerEvents: 'none',
					zIndex: 10000,
					marginBottom: 1,
				}}
			>
				DEVELOPMENT MODE
			</Button>
		)
	}

	return null
}

export default ModeWarning
