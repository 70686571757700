import { useState, useEffect, useContext } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import {
	Grid,
	Box,
	Card,
	CardContent,
	CardMedia,
	Divider,
	Typography,
} from '@mui/material'
import LogoSmall from '../images/imagotipo.png'
import { LanguageContext } from '../../context/languageContext'
import SupportCardButton from '../components/buttons/SupportCardButton'
import TriangleImage404 from '../images/icon-404-up-right.png'

function NotFound() {
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const [redirect, setRedirect] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		const timeoutId = setTimeout(() => {
			navigate('/')
		}, 10000)
		return () => clearTimeout(timeoutId)
	}, [])

	return (
		<>
			<Grid item xs={12}>
				<Grid container justifyContent="center">
					<Box component={'div'}>
						<Card
							sx={{
								minWidth: { xs: 310, sm: 340 },
								maxWidth: 640,
								borderRadius: '15px',
								boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
								position: 'relative',
							}}
						>
							<CardContent>
								<CardMedia
									component="img"
									image={`${LogoSmall}`}
									title="LogoSmall"
									sx={{
										margin: 'auto',
										display: 'block',
										width: 'auto',
										maxHeight: '100px',
									}}
								/>

								<CardMedia
									component="img"
									image={TriangleImage404}
									alt="Triángulo"
									sx={{
										position: 'absolute',
										top: 0,
										right: 0,
										maxHeight: {
											xs: '60px',
											sm: '70px',
											md: '80px',
											lg: '90px',
											xl: '90px'
										},
										maxWidth: {
											xs: '60px',
											sm: '70px',
											md: '80px',
											lg: '90px',
											xl: '90px'
										},
										margin: 1.5
									}}
								/>

								<Divider variant="middle" sx={{
									mx: {
										xs: 4,
										sm: 6,
										md: 16,
										lg: 20,
									},
								}} />
								<Typography
									variant="h6"
									align="center"
									color="text.secondary"
									gutterBottom
								>
									<strong>{translate('title_not_found')}</strong>
								</Typography>
								<Typography
									variant="body2"
									align="center"
									sx={{ mb: 3 }}
									color="text.secondary"
									fontSize={{
										xs: 10,
										sm: 12,
										md: 18,
										lg: 18,
										xl: 18
									}}
								>
									{translate('subtitle_not_found')}
									<br />
									{translate('text_not_found')}
								</Typography>
							</CardContent>
							<SupportCardButton />
						</Card>
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

export default NotFound
