import { useEffect, useState } from 'react'

const useCardFlip = (componentRef, status, service) => {
	const [isFlipped, setIsFlipped] = useState(false)
	useEffect(() => {
		const timer = setTimeout(() => {
			if (!componentRef.current?.contains(document.activeElement)) {
				setIsFlipped(false)
			}
		}, 10000)

		return () => {
			clearTimeout(timer)
		}
	}, [componentRef, isFlipped, setIsFlipped])

	const handleFlip = (event) => {
		if (status.connection !== 'online' && service !== 'hub') {
			return
		}
		event.preventDefault()
		setIsFlipped(!isFlipped)
	}

	return { handleFlip, isFlipped }
}

export default useCardFlip
