import { useContext, useState, forwardRef, ReactElement, Ref } from 'react'
import { useCookies } from 'react-cookie'
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Slide,
	Typography,
} from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { green, grey, red } from '@mui/material/colors'
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined'
import { LanguageContext } from '../../../context/languageContext'
import { useAuthContext } from '../../../context/authContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import useLogout from '../../hooks/useLogout'
import { useVersion } from '../../../context/appVersionContext'
import { useNavigation } from '../../../context/navigationContext'

const Transition = forwardRef(function Transition(
	props: TransitionProps & {
		children: ReactElement
	},
	ref: Ref<unknown>
) {
	return <Slide direction="down" ref={ref} {...props} />
})

const LogoutButton = () => {
	const { navigateToHome } = useNavigation()
	const { removeVersion, appVersion } = useVersion()
	const { removeUser } = useAuthContext()
	const [cookies, removeCookie] = useCookies()
	const { addSuccess } = useSuccessMessageContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const [openDialog, setOpenDialog] = useState(false)

	const handleLogOut = (): void => {
		useLogout(cookies, removeCookie, removeUser)
		removeVersion()

		addSuccess(`${translate('success_log_out')}`)
		navigateToHome(appVersion.path, language)
	}

	return (
		<>
			<Box>
				<Button
					variant="text"
					sx={{
						py: 2,
						px: 4,
						color: grey[600],
						fontSize: 10,
						fontWeight: 300,
						backgroundColor: '#f9e8e9',
						'&.MuiButton-root': {
							borderRadius: '0px',
						},
						'& .MuiSvgIcon-fontSizeMedium': {
							fontSize: 40,
						},
						'&:hover': {
							color: red[500],
							backgroundColor: '#ff7e7e',
						},
					}}
					onClick={() => setOpenDialog(true)}
					fullWidth
					startIcon={<PowerSettingsNewOutlinedIcon />}
				>
					<Box sx={{ width: '100%' }}>
						<Typography
							variant="h5"
							color="black"
							align="left"
							fontFamily={'Barlow Condensed'}
						>
							<b>{translate('button_logout_profile_bar')}</b>
						</Typography>
					</Box>
				</Button>
			</Box>

			<Dialog
				open={openDialog}
				TransitionComponent={Transition}
				onClose={() => setOpenDialog(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					<Typography
						variant="h5"
						align="center"
						sx={{ fontWeight: 600 }}
						color="black"
					>
						{translate('title_logout_dialog')}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						<Typography variant="body2" align="center" color="#757575">
							{translate('text_logout_dialog')}
						</Typography>
					</DialogContentText>
				</DialogContent>
				<DialogActions sx={{ mx: 2, my: 1 }}>
					<Button
						onClick={() => setOpenDialog(false)}
						sx={{
							backgroundColor: '#efefef',
							'&:hover': {
								backgroundColor: green['A100'],
							},
						}}
					>
						{translate('button_cancel')}
					</Button>
					<Button
						onClick={handleLogOut}
						autoFocus
						sx={{
							backgroundColor: '#efefef',
							'&:hover': {
								backgroundColor: green['A100'],
							},
						}}
					>
						{translate('button_accept')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	)
}

export default LogoutButton
