import { FC, useContext, useEffect, useState } from 'react'
import ModeWarning from './components/common/ModeWarning'
import { useLocation, useNavigate } from 'react-router'
import { Box, Grid } from '@mui/material'
import { customAppStyles } from './ludusTheme.d'
import Router from './routes/router'
import ToastSnackbar from './components/common/ToastSnackbar'
import { testURL } from './utils/testURL'
import { useStatusContext } from '../context/statusContext'
import { LanguageContext } from '../context/languageContext'
import { useNavigation } from '../context/navigationContext'

const languages = {
	es: 'es',
	en: 'en',
	'es-lt': 'es-lt',
	eu: 'eu',
	ca: 'ca',
	de: 'de',
	fr: 'fr',
	it: 'it',
	ms: 'ms',
	nl: 'nl',
	pl: 'pl',
	pt: 'pt',
	'pt-br': 'pt-br',
	tr: 'tr',
}
const platformLangs = [
	'de',
	'ca',
	'es',
	'es-lt',
	'en',
	'eu',
	'fr',
	'it',
	'ms',
	'nl',
	'pl',
	'pt',
	'pt-br',
	'test',
	'tr',
]
const App: FC = () => {
	const [ipcArg, setIpcArg] = useState('')
	const [isOnline, setIsOnline] = useState('')
	const { navigateToError } = useNavigation()

	const { updateStatus } = useStatusContext()
	const location = useLocation()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	useEffect(() => {
		const currentUrl = window.location.href
		testURL(currentUrl)
	}, [])

	useEffect(() => {
		const handleConnectionChange = () => {
			setIsOnline(navigator.onLine ? 'online' : 'offline')
		}
		isOnline === '' && setIsOnline(navigator.onLine ? 'online' : 'offline')
		window.addEventListener('online', handleConnectionChange)
		window.addEventListener('offline', handleConnectionChange)

		return () => {
			window.removeEventListener('online', handleConnectionChange)
			window.removeEventListener('offline', handleConnectionChange)
		}
	}, [])

	useEffect(() => {
		updateStatus(isOnline)
	}, [isOnline])

	const handleStartupArg = (arg: string) => {
		if (platformLangs.includes(arg)) {
			setLanguage(arg)
		}
		if (arg.includes('hub')) setIpcArg('hub')
		if (arg.includes('logout')) setIpcArg('logout')
		if (arg.includes('launched')) setIpcArg('launched')
	}

	useEffect(() => {
		if (
			window.localStorage.getItem('language') &&
			window.localStorage.getItem('language') ===
			window.localStorage.getItem('language').toUpperCase()
		) {

			window.localStorage.setItem(
				'language',
				window.localStorage.getItem('language').toLowerCase()
			)
			setLanguage(window.localStorage.getItem('language').toLowerCase())
		}

		if (!window.localStorage.getItem('language')) {
			setLanguage(navigator.language.includes('es') ? 'es' : 'en')
		}

		const searchString = location.search.toLowerCase()

		for (const [key, value] of Object.entries(languages)) {
			if (searchString.includes(key)) {
				setLanguage(value)
				break
			}
		}
		document.title = `${translate('title_tag_web')}`
	}, [location])

	useEffect(() => {
		const handleErrors = (error) => {
			console.error('Error captured', error)
			navigateToError()
		}

		window.onerror = (...params) =>
			handleErrors(new Error('Window onerror captured'))

		window.onunhandledrejection = (event) =>
			handleErrors(new Error('Unhandled rejection captured'))

		return () => {
			window.onerror = null
			window.onunhandledrejection = null
		}
	}, [navigateToError])

	const shouldShowModeWarning = !location.pathname.includes('statistics')

	return (
		<>
			<Box component={'div'} sx={customAppStyles.contentBox}>
				<Grid sx={{ flexGrow: 1 }} container>
					{shouldShowModeWarning && <ModeWarning />}
					<Router />
				</Grid>
				<ToastSnackbar />
			</Box>
		</>
	)
}

export default App
