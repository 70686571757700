import { useState, useEffect, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
	Card,
	CardContent,
	CardMedia,
	Divider,
	Grid,
	Box,
	Typography,
} from '@mui/material'
import LogoSmall from '../images/imagotipo.png'
import { LanguageContext } from '../../context/languageContext'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { userFind, userFindServices } from '../services/userFind'
import VerifyAccountForm from '../components/forms/verifyAccountForm'
import SupportCardButton from '../components/buttons/SupportCardButton'
import { decodeValidationToken } from '../utils/JWTE'
import { Services, UserFindRequest } from '../types/types.d'

function Signin() {
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const { addError } = useErrorMessageContext()

	const { userId } = useParams()

	let navigate = useNavigate()

	const [userData, setUserData] = useState<UserFindRequest>()
	const [userServices, setUserServices] = useState<Services>()
	const [countDown, setCountDown] = useState(false)
	const [count, setCount] = useState(10)

	useEffect(() => {
		const decodedToken = (async function (token: string) {
			const { status, data, code } = await decodeValidationToken(token)
			if (userId === '' || !userId) {
				addError(`${translate('error_undefined')}`)
				setCountDown(true)
			} else {
				findUserDataById(userId)
			}
		})(userId!)
	}, [])

	const findUserDataById = (id: string) => {
		const searchUser = (async function (id: string) {
			const { status, data, code } = await userFind(id)
			if (status !== 200) {
				addError(`${translate('error_undefined')}`)
				setCountDown(true)
			} else {
				setUserData(data)
			}
		})(id!)

		const searchServices = (async function (id: string) {
			const { status, data, code } = await userFindServices(id)
			if (status !== 200) {
				addError(`${translate('error_undefined')}`)
				setCountDown(true)
			} else {
				setUserServices(data)
			}
		})(id!)
	}

	useEffect(() => {
		if (countDown) {
			const interval = setInterval(() => {
				setCount((prevCount) => prevCount - 1)
			}, 1000)

			return () => {
				clearInterval(interval)
			}
		}
	}, [countDown])

	useEffect(() => {
		if (count === 0) {
			return navigate(`../?${language}`, { replace: true })
		}
	}, [count])

	return (
		<Grid item xs={12}>
			<Grid container justifyContent="center">
				{countDown ? (
					<Box component={'div'}>
						<Card
							sx={{
								minWidth: { xs: 310, sm: 340 },
								maxWidth: 640,
								borderRadius: '15px',
								boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
							}}
						>
							<CardContent>
								<CardMedia
									component="img"
									image={`${LogoSmall}`}
									title="LogoSmall"
									sx={{
										margin: 'auto',
										display: 'block',
										width: 'auto',
										maxHeight: '100px',
									}}
								/>

								<Divider variant="middle" sx={{
									mx: {
										xs: 4,
										sm: 6,
										md: 16,
										lg: 20,
									},
								}} />
								<Typography
									variant="h6"
									align="center"
									color="text.secondary"
									fontSize={{
										xs: 14,
										sm: 16,
										md: 18,
										lg: 18,
										xl: 18
									}}
								>
									<strong>
										{userData?.verified
											? `${translate('title_confirm_pass_error_already_activated')}`
											: `${translate('text_not_found')}`}
									</strong>
								</Typography>
								<Typography
									variant="body2"
									align="center"
									sx={{ mb: 3 }}
									color="text.secondary"
									fontSize={{
										xs: 12,
										sm: 12,
										md: 18,
										lg: 18,
										xl: 18
									}}
								>
									{`${translate('messagge_success_redirect')} ${count}`}
								</Typography>
							</CardContent>
							<SupportCardButton />
						</Card>
					</Box>
				) : (
					<VerifyAccountForm
						user={userData!}
						services={userServices!}
						setCountDown={setCountDown}
					/>
				)}
			</Grid>
		</Grid>
	)
}

export default Signin
