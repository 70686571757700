import { useState, useEffect, useContext, useRef } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
	UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
} from 'react-query'
import { useCookies } from 'react-cookie'
import {
	Grid,
	Box,
	Card,
	CardContent,
	CardMedia,
	Divider,
	Typography,
} from '@mui/material'
import LogoSmall from '../images/logo-ludus.png'
import { LanguageContext } from '../../context/languageContext'
import { userCheckId } from '../services/userFind'
import { userChangePasswordConfirmation } from '../services/userChangePassword'
import SupportCardButton from '../components/buttons/SupportCardButton'
import { decodeResetCodeToken } from '../utils/JWTE'
import { useAuthContext } from '../../context/authContext'
import useLogout from '../hooks/useLogout'

function VerifyPassword() {
	const { removeUser } = useAuthContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const [cookies, setCookie, removeCookie] = useCookies()

	const { token } = useParams()

	let navigate = useNavigate()
	let confirmPasswordRequest
	let user

	const [id, setId] = useState()
	const [userChangeCode, setUserChangeCode] = useState()
	const [index, setIndex] = useState(0)
	const [countDown, setCountDown] = useState(false)
	const [count, setCount] = useState(10)

	const cardMessage = useRef([`${translate('messagge_verify_password')}`])

	useEffect(() => {
		const decodedToken = (async function (token: string) {
			const { status, data, code } = await decodeResetCodeToken(token)

			if (status !== 200)
				cardMessage.current = [
					...cardMessage.current,
					`${translate('messagge_verify_code_not_match')}`,
				]

			setId(data.userId)
			setUserChangeCode(data.userResetCode)
		})(token!)
	}, [])

	const queryClient = useQueryClient()

	const userMutation = useMutation(
		['user', id],
		async () => {
			user = await userCheckId(id)
			if (user.status === 200) {
				cardMessage.current = [
					...cardMessage.current,
					`${translate('messagge_verify_user_data_success')}`,
				]
			} else {
				cardMessage.current = [
					...cardMessage.current,
					`${translate('messagge_verify_user_data_not_found')}`,
				]
			}
			return user.data
		},
		{
			onSuccess: (data) => {
				if (data) {
					cardMessage.current = [
						...cardMessage.current,
						`${translate('messagge_verify_user_data_success')}`,
					]
				}
			},
			onError: () => {
				cardMessage.current = [
					...cardMessage.current,
					`${translate('messagge_verify_user_data_not_found')}`,
				]
			},
			retry: false,
		}
	)

	const confirmPasswordMutation = useMutation(
		'confirmPasswordChange',
		async () => {
			confirmPasswordRequest = await userChangePasswordConfirmation(
				id,
				userChangeCode
			)

			if (confirmPasswordRequest.status === 200) {
				cardMessage.current = [
					...cardMessage.current,
					`${translate('messagge_verify_success')}`,
				]
				setCountDown(true)
			} else if (
				confirmPasswordRequest.status === 400 &&
				confirmPasswordRequest.data.code === 'E21309'
			) {
				cardMessage.current = [
					...cardMessage.current,
					`${translate('messagge_verify_code_not_match')}`,
				]
			} else {
				cardMessage.current = [
					...cardMessage.current,
					`${translate('messagge_verify_error')}`,
				]
			}
			return confirmPasswordRequest.data
		},
		{
			onSuccess: (data) => {
				if (data && data.status === 200) {
					cardMessage.current = [
						...cardMessage.current,
						`${translate('messagge_verify_success')}`,
					]
				}
			},
			onError: (error: any) => {
				if (error.code === 'E21309') {
					cardMessage.current = [
						...cardMessage.current,
						`${translate('messagge_verify_code_not_match')}`,
					]
				} else {
					cardMessage.current = [
						...cardMessage.current,
						`${translate('messagge_verify_error')}`,
					]
				}
			},
			retry: false,
		}
	)

	useEffect(() => {
		if (id) {
			userMutation.mutate()
		}
	}, [id])

	useEffect(() => {
		if (id && userChangeCode) {
			confirmPasswordMutation.mutate()
		}
	}, [id, userChangeCode])
	useEffect(() => {
		if (user?.isLoading)
			cardMessage.current = [
				...cardMessage.current,
				`${translate('messagge_verify_user_data_find')}`,
			]
		if (confirmPasswordRequest?.isLoading && user?.data?.status === 200)
			cardMessage.current = [
				...cardMessage.current,
				`${translate('messagge_verify_processing')}`,
			]
	}, [
		confirmPasswordRequest?.status,
		user?.isLoading,
		user?.data,
		confirmPasswordRequest?.isLoading,
	])

	useEffect(() => {
		if (cardMessage.current.length > 0) {
			const interval = setInterval(() => {
				setIndex((index) => {
					const nextIndex = index + 1
					if (nextIndex >= cardMessage.current.length) {
						clearInterval(interval)
						setCountDown(true)
					}
					return nextIndex < cardMessage.current.length ? nextIndex : index
				})
			}, 2000)
			return () => clearInterval(interval)
		}
	}, [])

	useEffect(() => {
		if (countDown) {
			const interval = setInterval(() => {
				setCount((prevCount) => prevCount - 1)
			}, 1000)

			return () => {
				clearInterval(interval)
			}
		}
	}, [countDown])

	useEffect(() => {
		if (count === 0) {
			useLogout(cookies, removeCookie, removeUser)
			return navigate('../', { replace: true })
		}
	}, [count])

	return (
		<Grid item xs={12}>
			<Grid container justifyContent="center">
				<Box component={'div'}>
					<Card
						sx={{
							minWidth: 275,
							maxWidth: 540,
							borderRadius: '15px',
							boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
						}}
					>
						<CardContent sx={{ mb: -1 }}>
							<CardMedia
								component="img"
								image={`${LogoSmall}`}
								title="LogoSmall"
								sx={{
									margin: 'auto',
									mt: 3,
									display: 'block',
									width: '100%',
									maxHeight: '100px',
								}}
							/>
							<Divider variant="middle" sx={{ mx: 20 }} />
							<Box sx={{ m: 3 }}>
								<Typography
									variant="h5"
									align="center"
									sx={{ fontWeight: 600 }}
									color="black"
								>
									{`${translate('title_verify_password')}`}
								</Typography>
								<Typography
									variant="body2"
									align="center"
									sx={{ mt: 1, mb: 3 }}
									color="#757575"
								>
									<strong>
										{countDown
											? `${translate('messagge_success_redirect')} ${count}`
											: cardMessage.current[index]}
									</strong>
								</Typography>
							</Box>
						</CardContent>
						<SupportCardButton />
					</Card>
				</Box>
			</Grid>
		</Grid>
	)
}

export default VerifyPassword
