import {
	createContext,
	useState,
	useCallback,
	useContext,
	ReactNode,
	FC,
} from 'react'
import { UserData } from '../common/types/types.d'

type Props = {
	children: ReactNode
}

export const AuthContext = createContext({
	user: {
		id: '',
		email: '',
		firstName: '',
		lastName: '',
		role: {
			services: { VrAcademy: false, LRS: false, Shop: false, HUB: false },
		},
		rememberMe: false,
		version: { portal: '', authAPI: '' },
		deploymentGroup: {
			name: '',
			client: '',
			portal: '',
			usage_statistics: '',
			api: '',
		},
	},
	addUser: (
		id: string,
		email: string,
		firstName: string,
		lastName: string,
		role: UserData['role'],
		rememberMe: boolean,
		version: UserData['version'],
		deploymentGroup: UserData['deploymentGroup']
	) => {},
	removeUser: () => {},
})

export const AuthContextProvider: FC<Props> = ({ children }): JSX.Element => {
	const [user, setUser] = useState({
		id: '',
		email: '',
		firstName: '',
		lastName: '',
		role: {
			services: { VrAcademy: false, LRS: false, Shop: false, HUB: false },
		},
		rememberMe: false,
		version: { portal: '', authAPI: '' },
		deploymentGroup: {
			name: '',
			client: '',
			portal: '',
			usage_statistics: '',
			api: '',
		},
	})

	const addUser = (
		id: string,
		email: string,
		firstName: string,
		lastName: string,
		role: UserData['role'],
		rememberMe: boolean,
		version: UserData['version'],
		deploymentGroup: UserData['deploymentGroup']
	) =>
		setUser({
			id,
			email,
			firstName,
			lastName,
			role,
			rememberMe,
			version,
			deploymentGroup,
		})

	const contextValue = {
		user: user,
		addUser: useCallback(
			(
				id: string,
				email: string,
				firstName: string,
				lastName: string,
				role: UserData['role'],
				rememberMe: boolean,
				version: UserData['version'],
				deploymentGroup: UserData['deploymentGroup']
			) =>
				addUser(
					id,
					email,
					firstName,
					lastName,
					role,
					rememberMe,
					version,
					deploymentGroup
				),
			[]
		),
		removeUser: useCallback(
			() =>
				setUser({
					id: '',
					email: '',
					firstName: '',
					lastName: '',
					role: {
						services: { VrAcademy: false, LRS: false, Shop: false, HUB: false },
					},
					rememberMe: false,
					version: { portal: '', authAPI: '' },
					deploymentGroup: {
						name: '',
						client: '',
						portal: '',
						usage_statistics: '',
						api: '',
					},
				}),
			[]
		),
	}

	return (
		<AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
	)
}

export function useAuthContext() {
	const { user, addUser, removeUser } = useContext(AuthContext)
	return { user, addUser, removeUser }
}
