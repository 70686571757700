import { useContext } from 'react'
import { useCookies } from 'react-cookie'
import { CookieSetOptions, Cookie } from 'universal-cookie'
import validateUserOnline from '../services/userLogin'
import { decodeJwt } from '../utils/JWTE'
import { LoginUserRequest, LoginUserResponse, UserData } from '../types/types.d'
import { useAuthContext } from '../../context/authContext'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../context/successMessageContext'
import { LanguageContext } from '../../context/languageContext'
import { useNavigation } from '../../context/navigationContext'
import { useVersion } from '../../context/appVersionContext'

type ResetFieldFunction = (fieldName: string) => void

function useUserAuthentication() {
	const { addUser } = useAuthContext()
	const { navigateToServices } = useNavigation()
	const { setVersion, determinePathVersion, appVersion } = useVersion()
	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()

	const [cookies, setCookie] = useCookies()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const handleUserAuthentication = async (
		request: LoginUserRequest,
		resetField: ResetFieldFunction,
		rememberMeCheck: boolean
	) => {
		const { status, data, error, code } = await validateUserOnline(request)

		if (status !== 200) {
			resetField('password')
			addError(`${translate(`${data.code}`)}`)
		} else {
			const { userToken } = data
			const userData: UserData | null = decodeJwt(userToken)
			const accessToken = window.sessionStorage.getItem('accessToken')
			if (userData === null) {
				addError(`${translate('error_login_token')}`)
			} else {
				const { id, email, firstName, lastName, role, deploymentGroup } = userData
				const version = {
					portal: deploymentGroup.portal,
					authAPI: deploymentGroup.api,
				}
				addUser(
					id,
					email,
					firstName,
					lastName,
					role,
					rememberMeCheck,
					version,
					deploymentGroup
				)
				setVersion(version.portal, version.authAPI)

				if (rememberMeCheck) {
					await dataPersistanceHandler(userData, setCookie)
				}
				addSuccess(`${translate(`success_login_welcome`)} ${firstName}`)
				navigateToServices(determinePathVersion(version.portal), language)
			}
		}
	}

	return handleUserAuthentication
}

async function dataPersistanceHandler(
	userData: LoginUserResponse,
	setCookie: (name: string, value: Cookie, options?: CookieSetOptions) => void
) {
	setCookie(
		'AuthToken',
		{ email: btoa(userData.email) },
		{
			path: '/',
			maxAge: 2592000,
		}
	)
}

export default useUserAuthentication
