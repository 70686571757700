import axios from 'axios'

export type ContactSupportEmailRequest = {
	email: string
	name: string
	phone: string
	text: string
}

export type ContactSupportEmailResponse = {
	data: string | boolean | object
}

export default async function contactSupportEmail(request: ContactSupportEmailRequest, apiVersion: string) {
	return await axios
		.post<ContactSupportEmailResponse>(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/contactSupport`,
			{ ...request },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
					'x-api-version': `${apiVersion}`
				},
			}
		)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			console.error(error)
			return error.response.data
		})
}
