import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material'
import suportButton from '../../images/boton-soporte.png'
import { useAuthContext } from '../../../context/authContext'
import { LanguageContext } from '../../../context/languageContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useStatusContext } from '../../../context/statusContext'
import Header from '../../components/common/Header'
import ProfileNavBar from '../../components/navbars/ProfileNavBar'
import { cardConfig } from '../../utils/cardConfig'
import useSetAcademyUrlLogin from '../../hooks/useSetAcademyUrlLogin'
import useUserServiceCards from '../../hooks/useConfigServiceCards'
import useRemoveCookiesOnClose from '../../hooks/useRemoveCookiesOnClose'
import useDecodeUserToken from '../../hooks/useDecodeUserToken'
import { underMaintenance } from '../../utils/underMaintenance'
import { ServiceData } from '../../types/types.d'
import { useVersion } from '../../../context/appVersionContext'

import IframeCard from '../../components/cards/IframeCard'
import MenuNavBar from '../navbars/MenuNavBar'

const MainWrapper = (props) => {
	const { user, addUser, removeUser } = useAuthContext()
	const [cookies, setCookie, removeCookie] = useCookies()
	const { appVersion } = useVersion()
	const { addError } = useErrorMessageContext()
	const { status } = useStatusContext()
	let navigate = useNavigate()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const { maintenance, formattedDate } = underMaintenance(language)

	const [userServices, setUserServices] = useState<ServiceData[]>([])

	const [academy, setAcademy] = useState({
		loginURL: '',
	})

	const [chargeBee, setChargeBee] = useState({
		loginURL: '',
	})

	useEffect(() => {
		useSetAcademyUrlLogin(user, status, setAcademy, addError, translate)
	}, [user])

	useEffect(() => {
		const autologin = async () => {
			await useDecodeUserToken(cookies, addUser, addError, navigate, translate)
		}
		autologin()
	}, [])

	useEffect(() => {
		useUserServiceCards(user, academy, cardConfig, chargeBee, setUserServices)
	}, [user, academy])

	useRemoveCookiesOnClose(user, removeUser, cookies, removeCookie)

	const [openMyProfile, setOpenMyProfile] = useState(false)

	const handleClickMyProfile = () => {
		setOpenMyProfile(!openMyProfile)
	}

	const [openSupportForm, setOpenSupportForm] = useState(false)

	const handleClickSupportForm = () => {
		if (status.connection !== 'online') {
			return addError(`${translate('error_connection')}`)
		}
		setOpenSupportForm(!openSupportForm)
	}

	const [menuNavBarOpen, setMenuNavBarOpen] = useState(false)

	const handleMenuNavBarClick = () => {
        setMenuNavBarOpen(true)
    }

    const handleCloseMenuNavBar = () => {
        setMenuNavBarOpen(false)
    }

	return (
		<>
			<Header handleClick={handleClickMyProfile} handleMenuClick={handleMenuNavBarClick}/>
			<Box component={'div'} sx={{ display: 'none' }}>
				<ProfileNavBar variant="permanent" open={true} />
			</Box>
			<Box component={'div'} sx={{ display: 'none' }}>
				<ProfileNavBar
					variant="temporary"
					open={openMyProfile}
					onClose={handleClickMyProfile}
				/>
			</Box>
			<MenuNavBar open={menuNavBarOpen} onClose={handleCloseMenuNavBar} />
			{props.children}
		</>
	)
}

export default MainWrapper
