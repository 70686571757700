import { useState, MouseEvent } from 'react'
import { Box, IconButton } from '@mui/material'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import ProfileForm from '../forms/ProfileForm'
import ProfileEmailForm from '../forms/ProfileEmailForm'
import ProfilePasswordForm from '../forms/ProfilePasswordForm'
import ProfileNameForm from '../forms/ProfileNameForm'

type FormProps = {
	setProfileBarTitle: (title: string) => void
	onClose: () => void
}

export default function ProfileNavList(props: FormProps) {
	const { setProfileBarTitle, onClose } = props
	const [showed, setShown] = useState({
		profileForm: true,
		emailForm: false,
		passwordForm: false,
		nameForm: false,
	})
	const handleClick = (
		event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
	): void => {
		switch (event.currentTarget.id) {
			case 'emailForm':
				setProfileBarTitle('title_profile_change_email')
				break
			case 'passwordForm':
				setProfileBarTitle('title_profile_change_password')
				break
			case 'nameForm':
				setProfileBarTitle('title_profile_change_name')
				break

			default:
				break
		}
		setShown({ ...showed, profileForm: false, [event.currentTarget.id]: true })
	}

	const handleClose = (): void => {
		setShown({
			profileForm: true,
			emailForm: false,
			passwordForm: false,
			nameForm: false,
		})
		setProfileBarTitle('title_my_profile')
	}

	return (
		<>
			{!showed.profileForm && (
				<IconButton
					aria-label="delete"
					color="primary"
					sx={{
						position: 'absolute',
						zIndex: 10,
					}}
					onClick={handleClose}
					id="profileForm"
				>
					<ArrowCircleLeftIcon sx={{ fontSize: 45 }} />
				</IconButton>
			)}

			<Box
				sx={{
					width: '100%',
					height: '40vh',
					margin: 'auto',
					bgcolor: 'background.paper',
					display: 'flex',
					flexDirection: 'column',
					flexWrap: 'nowrap',
					justifyContent: 'center',
					alignItems: 'center',
					alignContent: 'center',
					py: '2vh',
					px: 4,
				}}
			>
				{showed.profileForm && <ProfileForm handleClick={handleClick} />}
				{showed.emailForm && <ProfileEmailForm onClose={onClose} />}
				{showed.passwordForm && <ProfilePasswordForm onClose={onClose} />}
				{showed.nameForm && <ProfileNameForm onClose={onClose} />}
			</Box>
		</>
	)
}
