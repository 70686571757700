import axios from 'axios'

export type ChangePasswordUserRequest = {
	id: string
	passwordChangeCode: string
	newPassword: string
}

export type ConfirmPasswordUserRequest = {
	email: string
	oldPassword: string
	newPassword: string
	confirmPassword: string
}

export async function userChagePasswordRequest(request: ConfirmPasswordUserRequest, apiVersion: string) {
	const { email, oldPassword, newPassword, confirmPassword } = request

	return await axios
		.post(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/changePassword`,
			{
				email: email,
				oldPassword: oldPassword,
				newPassword: newPassword,
				confirmPassword: confirmPassword,
			},
			{
				headers: {
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					'x-api-version': `${apiVersion}`
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			return error.response.data
		})
}

export const userChangePasswordConfirmation = async (
	id: string | undefined,
	passwordChangeCode: string | undefined
) => {
	try {
		const response = await axios.get(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/verifyPassword/${id}/${passwordChangeCode}`,
			{
				headers: {
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
				},
			}
		)

		return response.data
	} catch (error) {
		console.error(error)

		throw error.response.data
	}
}
