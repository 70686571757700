import axios from 'axios'
import { ApiResponse, LoginUserRequest, LoginUserResponse } from '../types/types.d'


export default async function validateUserOnline(
	request: LoginUserRequest
): Promise<{
	status: number
	data: ApiResponse
	error: string | boolean | object
	code: string
}> {
	const { email, password } = request
	const { accessToken } = await authenticateUser(email, password)

	return await loginUserOnline(accessToken)
}

export const authenticateUser = async (email: string, password: string) => {
	return await axios
		.post(
			`${process.env.REACT_APP_AUTH_API_URL}/api/sessions/login`,
			{ email: email, password: password },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
					'x-api-version': process.env.REACT_APP_LOGIN_API_VERSION
				},
			}
		)
		.then((response) => {
			sessionStorage.setItem('accessToken', response.data.data.accessToken)
			return response.data.data
		})
		.catch((error) => {
			return error.response
		})
}

export const loginUserOnline = async (accessToken) => {
	return await axios
		.post<LoginUserResponse>(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/`,
			{ accessToken },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
				},
			}
		)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			return error.response
		})
}
