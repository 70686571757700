import axios from 'axios'

export type LoginLRSResponse = {
	userId: string
	userToken: {
		status: number
		data: string
	}
}

export default async function LearningLockerLogin(email: string, apiVersion: string = process.env.REACT_APP_LOGIN_API_VERSION) {
	return await axios
		.post<LoginLRSResponse>(
			`${process.env.REACT_APP_AUTH_API_URL}/api/lrs/login`,
			{ email },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
					'x-api-version': `${apiVersion}`
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			console.error(error)
			return error.response
		})
}
