import {
	useState,
	useCallback,
	useContext,
	ReactNode,
	createContext,
	FC,
} from 'react'

type Props = {
	children: ReactNode
}

export const StatusContext = createContext({
	status: { connection: '' },
	updateStatus: (connection: string) => {},
	removeStatus: () => {},
})

export const StatusContextProvider: FC<Props> = ({ children }): JSX.Element => {
	const [status, setStatus] = useState({ connection: '' })

	const removeStatus = () => setStatus({ connection: '' })

	const updateStatus = (connection: string) => setStatus({ connection })

	const contextValue = {
		status: status,
		updateStatus: useCallback(
			(connection: string) => updateStatus(connection),
			[]
		),
		removeStatus: useCallback(() => removeStatus(), []),
	}

	return (
		<StatusContext.Provider value={contextValue}>
			{children}
		</StatusContext.Provider>
	)
}

export function useStatusContext() {
	const { status, updateStatus, removeStatus } = useContext(StatusContext)
	return {
		status,
		updateStatus,
		removeStatus,
	}
}
