import { userVerifyAcademyAccount } from '../services/userVerifyAcademyAccount'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { LanguageContext } from '../../context/languageContext'
import { useSuccessMessageContext } from '../../context/successMessageContext'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { userVerifySsoAccount } from '../services/userVerifySsoAccount'
import { userVerifyLRSAccount } from '../services/userVerifyLRSAccount'

function useAccountVerification() {
	const [verificationStatus, setVerificationStatus] = useState('')

	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	let navigate = useNavigate()

	const verifyUserAccounts = async (request, user, services) => {
		const { password, passwordConfirmation } = request
		const requestStatus = []
		const userDataRequest = {
			id: user.id,
			verificationCode: user.verificationCode,
			password,
			passwordConfirmation,
		}

		const verifyServiceAccount = async (serviceName, serviceVerifyFunction) => {
			if (services.some((service) => service[0] === serviceName && service[1])) {
				const { status, code } = await serviceVerifyFunction(user.email)
				requestStatus.push(status)
				if (status !== 200) {
					addError(translate(code))
				} else {
					addSuccess(translate(`success_confirm_${serviceName.toLowerCase()}`))
				}
			}
		}

		// Verificar cuentas de servicios específicos
		await verifyServiceAccount('VrAcademy', userVerifyAcademyAccount)
		await verifyServiceAccount('LRS', userVerifyLRSAccount)

		// Verificar cuenta SSO
		const { status, code } = await userVerifySsoAccount(userDataRequest)
		requestStatus.push(status)
		if (status !== 200) {
			addError(translate(code))
		} else {
			addSuccess(translate('success_confirm_pass'))
		}

		// Comprobar el estado de todas las solicitudes
		if (requestStatus.find((status) => status !== 200)) {
			setVerificationStatus('error')
		} else {
			navigate('../')
			window.location.reload()
			setVerificationStatus('success')
		}
	}

	return { verificationStatus, verifyUserAccounts }
}

export default useAccountVerification
