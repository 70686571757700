import { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { decodeUserToken } from '../utils/JWTE'
import { useAuthContext } from '../../context/authContext'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { LanguageContext } from '../../context/languageContext'

function useTokenAuthentication() {
	const [isTokenAuthenticated, setIsTokenAuthenticated] = useState(false)
	const { addUser } = useAuthContext()
	const { addError } = useErrorMessageContext()
	let navigate = useNavigate()
	const [cookies, setCookie] = useCookies()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const authenticateWithToken = async (JWEToken) => {
		const { status, data, error } = await decodeUserToken(JWEToken)

		if (status !== 200) {
			addError(`${translate('error_login_token')}`)
		} else if (typeof data !== 'boolean' && typeof data !== 'string') {
			const { accessToken } = data as { accessToken: string }
			sessionStorage.setItem('accessToken', accessToken)
			setIsTokenAuthenticated(true)
			navigate('../services', { replace: true })
		}
	}

	return { isTokenAuthenticated, authenticateWithToken }
}

export default useTokenAuthentication
