import { useState, useEffect, useContext } from 'react'
import {
	Grid,
	Box,
	Card,
	CardContent,
	CardMedia,
	Divider,
	Typography,
} from '@mui/material'
import LogoSmall from '../images/imagotipo.png'
import { LanguageContext } from '../../context/languageContext'
import SupportCardButtonError from '../components/buttons/SupportCardButtonError'
import TriangleImageMaintenance from '../images/icon-maintenance-up-right.png'

function MaintenancePage() {
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	return (
		<>
			<Grid item xs={12}>
				<Grid container justifyContent="center">
					<Box component={'div'}>
						<Card
							sx={{
								minWidth: { xs: 310, sm: 340 },
								maxWidth: 640,
								borderRadius: '15px',
								boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
								position: 'relative',
							}}
						>
							<CardContent>
								<CardMedia
									component="img"
									image={`${LogoSmall}`}
									title="LogoSmall"
									sx={{
										margin: 'auto',
										display: 'block',
										width: 'auto',
										maxHeight: '100px',
									}}
								/>

								<CardMedia
									component="img"
									image={TriangleImageMaintenance}
									alt="Triángulo"
									sx={{
										position: 'absolute',
										top: 0,
										right: 0,
										maxHeight: {
											xs: '60px',
											sm: '70px',
											md: '80px',
											lg: '90px',
											xl: '90px'
										},
										maxWidth: {
											xs: '60px',
											sm: '70px',
											md: '80px',
											lg: '90px',
											xl: '90px'
										},
										margin: 1.5
									}}
								/>


								<Divider variant="middle" sx={{
									mb: 2,
									mx: {
										xs: 4,
										sm: 6,
										md: 16,
										lg: 20,
									},
								}} />
								<Typography
									variant="h6"
									align="center"
									color="text.secondary"
									gutterBottom
								>
									<strong>{translate('title_maintenance_page')}</strong>
								</Typography>
								<Typography
									variant="body2"
									align="center"
									sx={{ mt: 1, padding: 1 }}
									color="text.secondary"
									fontSize={{
										xs: 10,
										sm: 12,
										md: 18,
										lg: 18,
										xl: 18
									}}
								>
									{translate('subtitle_maintenance_page')}
								</Typography>
								<Typography
									variant="body2"
									align="center"
									sx={{ padding: 1 }}
									color="text.secondary"
									fontSize={{
										xs: 10,
										sm: 12,
										md: 18,
										lg: 18,
										xl: 18
									}}
								>
									{translate('text_maintenance_page_pt1')}
								</Typography>
							</CardContent>
							<SupportCardButtonError />
						</Card>
					</Box>
				</Grid>
			</Grid>
		</>
	)
}

export default MaintenancePage
