import { useContext, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { Box, TextField } from '@mui/material'
import { useAuthContext } from '../../../context/authContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import { LanguageContext } from '../../../context/languageContext'
import { GradientButton } from '../buttons/GradientButton'

type FormValues = {
	name: string
}

type ProfileNameFormProps = {
	onClose: () => void;
}

const ProfileNameForm = (props: ProfileNameFormProps) => {
	const { user } = useAuthContext()
	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors, submitCount, touchedFields, dirtyFields },
	} = useForm<FormValues>({
		defaultValues: {
			name: '',
		},
		delayError: 500,
		mode: 'onChange',
	})

	useEffect(() => {
		setValue(
			'name',
			user.firstName && user.lastName
				? `${user.firstName} ${user.lastName}`
				: 'name',
			{
				shouldValidate: false,
				shouldDirty: false,
			}
		)
	}, [user, setValue])

	const onSubmit = handleSubmit(async (request: FormValues) => {
		props.onClose()
	})

	return (
		<Box
			sx={{
				width: '100%',
				bgcolor: 'background.paper',
				display: 'flex',
				flexDirection: 'column',
				flexWrap: 'nowrap',
				justifyContent: 'center',
				alignItems: 'center',
				alignContent: 'stretch',
				py: '2vh',
			}}
		>
			<TextField
				{...register('name', {
					required: {
						value: true,
						message: `${translate('mistake_empty_name_field')}`,
					},
				})}
				error={errors?.name ? true : false}
				aria-label={`${translate('label_name_profile_form')}`}
				sx={{
					width: '100%',
					height: '80px',
					'.MuiFormHelperText-root': {
						fontFamily: 'Inter',
					},
					'.MuiInputLabel-root': {
						color: '#757575 !important',
					},
				}}
				label={`${translate('label_name_profile_form')}`}
				defaultValue={
					user.firstName && user.lastName
						? `${user.firstName} ${user.lastName}`
						: 'name'
				}
				helperText={errors?.name ? errors?.name.message : ''}
				variant="standard"
				color="primary"
				focused
				InputProps={{
					style: { fontSize: 14 },
				}}
			/>

			<GradientButton
				sx={{ color: 'white', padding: '14px 16px', mt: 2 }}
				variant="contained"
				onClick={onSubmit}
			>
				GUARDAR Y CONTINUAR
			</GradientButton>
		</Box>
	)
}

export default ProfileNameForm
