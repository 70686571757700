import { useEffect } from 'react'
import { decodeJwt } from '../utils/JWTE'
import { loginUserOnline } from '../services/userLogin'

const useDecodeUserToken = async (
	cookies,
	addUser,
	addError,
	navigate,
	translate
) => {
	if (window.sessionStorage.getItem('accessToken')) {
		const accessToken = window.sessionStorage.getItem('accessToken')

		const loginRequest = await loginUserOnline(accessToken)

		const userData: {
			id: string
			email: string
			firstName: string
			lastName: string
			role: {}
			version: {}
			deploymentGroup: {}
		} | null = decodeJwt(loginRequest.data?.userToken)
		if (userData === null) return addError(`${translate('error_login_token')}`)

		const { id, email, firstName, lastName, role, version, deploymentGroup } =
			userData
		const rememberMe = cookies.AuthToken ? true : false
		return addUser(
			id,
			email,
			firstName,
			lastName,
			role,
			rememberMe,
			version,
			deploymentGroup
		)
	} else {
		navigate('../', { replace: true })
	}
}

export default useDecodeUserToken
