import { signJWE } from './JWTE'

export async function createLudusLauncherPath(user, language) {
	const hubLanguage = language.toLowerCase()
	const JWE = await signJWE({
		accessToken: window.sessionStorage.getItem('accessToken'),
	})
	const path = `LudusLauncher://instructor_name=${btoa(
		user.firstName
	)}&instructor_id=${btoa(
		user.id
	)}&platform=web&language=${hubLanguage}&token=${JWE}`

	return path
}
