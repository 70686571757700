import { useContext } from 'react'
import { Box, CardContent, Typography } from '@mui/material'
import { LanguageContext } from '../../../context/languageContext'

type ServiceCardInfoProps = {
	subtitle: string
	text: string
}

const ServiceCardInfo = (props: ServiceCardInfoProps) => {
	const { subtitle, text } = props

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	return (
		<CardContent
			sx={{
				width: '100%',
				background: '#ffffff',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<Box
				component={'div'}
				sx={{
					height: '100%',
					width: '100%',
					padding: 1,
				}}
			>
				<Typography
					variant="h5"
					align="center"
					sx={{
						fontWeight: 600,
						whiteSpace: 'wrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					color="black"
				>
					{`${translate(subtitle)}`}
				</Typography>
				<Typography
					variant="body2"
					align="center"
					sx={{
						my: 1,
						paddingRight: 1,
						maxHeight: '15vh',
						maxWidth: '100%',
						whiteSpace: 'wrap',
						overflowY: 'auto',
						overflowX: 'hidden',
						'&::-webkit-scrollbar': {
							width: '0.3em',
						},
						'&::-webkit-scrollbar-track': {
							boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
							webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
						},
						'&::-webkit-scrollbar-thumb': {
							backgroundColor: 'rgba(0,0,0,.1)',
							borderRadius: 100,
							outline: '1px ',
						},
					}}
					color="#757575"
				>
					{`${translate(text)}`}
				</Typography>
			</Box>
		</CardContent>
	)
}

export default ServiceCardInfo
