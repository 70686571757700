import { useContext, useState } from 'react'
import {
	Box,
	Divider,
	Drawer,
	drawerClasses,
	IconButton,
	Typography,
} from '@mui/material'
import { green } from '@mui/material/colors'
import { drawerWidth, drawerWidthTablet } from '../../ludusTheme.d'
import Avatar from 'react-avatar'
import { LanguageContext } from '../../../context/languageContext'
import { useAuthContext } from '../../../context/authContext'
import LogoutButton from '../buttons/LogoutButton'
import PrivacyProfileButton from '../buttons/PrivacyProfileButton'
import ProfileNavList from './ProfileNavList'
import CloseIcon from '@mui/icons-material/Close'


type ProfileNavBarProps = {
	variant: "permanent" | "persistent" | "temporary";
	open: boolean;
	onClose?: () => void;
}

const ProfileNavBar = (props: ProfileNavBarProps) => {
	const { variant, open, onClose } = props
	const [profileBarTitle, setProfileBarTitle] = useState('title_my_profile')
	const { user } = useAuthContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	return (
		<>
			<Drawer
				sx={{
					flexShrink: 0,
					[`& .${drawerClasses.paper}`]: {
						width: { xs: '100%', sm: '100%', md: drawerWidthTablet, lg: drawerWidth, xl: drawerWidth },
						height: '100%',
						overflowX: 'hidden'
					},
				}}
				variant={variant}
				anchor="right"
				open={open}
				onClose={props.onClose ? props.onClose : null}
			>
				{profileBarTitle === 'title_my_profile' && (
					<IconButton
						aria-label="delete"
						color="primary"
						sx={{
							position: 'absolute',
							zIndex: 10,
						}}
						onClick={onClose}
						id="profileForm"
					>
						<CloseIcon sx={{ fontSize: 45 }} />
					</IconButton>
				)}
				<Box
					sx={{
						width: '100%',
						bgcolor: 'background.paper',
						marginLeft: 0,
						display: 'flex',
						flexDirection: 'column',
						flexWrap: 'nowrap',
						justifyContent: 'center',
						alignItems: 'center',
						alignContent: 'stretch',
						pt: '15vh',
					}}
				>
					<Avatar
						name={
							user.firstName && user.lastName
								? `${user.firstName} ${user.lastName}`
								: 'User'
						}
						size="100"
						alt="avatar"
						aria-label="avatar"
						color={green['A100']}
						fgColor="black"
						round="100px"
					/>
					<Box
						sx={{
							paddingX: 3,
						}}
					>
						<Typography
							variant="h5"
							align="center"
							sx={{ mt: 1, fontWeight: 600 }}
							color="black"
						>
							{profileBarTitle === 'title_my_profile'
								? `${user.firstName} ${user.lastName}`
								: translate(profileBarTitle)}
						</Typography>
					</Box>
				</Box>
				<ProfileNavList
					setProfileBarTitle={setProfileBarTitle}
					onClose={props.onClose}
				/>
				<Divider />
				<PrivacyProfileButton />
				<Divider />
				<LogoutButton />
			</Drawer>
		</>
	)
}

export default ProfileNavBar
