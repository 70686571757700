import { useEffect } from 'react'
import academyLogin from '../services/academyLogin'

const useSetAcademyUrlLogin = (
	user,
	status,
	setAcademy,
	addError,
	translate,
) => {
	const setAcademyUrlLoginAsync = async () => {
		if (
			user.email !== '' &&
			user.role.services.VrAcademy &&
			status.connection === 'online'
		) {
			const { status, data, code } = await academyLogin(user.email, user.deploymentGroup.api)
			if (status !== 200) {
				return addError(`${translate(code)}`)
			}

			setAcademy({
				loginURL: data.response,
			})
		}
	}

	setAcademyUrlLoginAsync()
}

export default useSetAcademyUrlLogin
