import { useContext, useState } from 'react'
import { Box, Button, CardContent, SvgIcon, Typography } from '@mui/material'
import { ReactComponent as infoIcon } from '../../images/icons/Informacion.svg'
import { LanguageContext } from '../../../context/languageContext'

type ServiceCardInfoProps = {
	bgImage: string[]
	title: string
	subtitle: string
	text: string
	cardNumber: number
}

const ServiceCardContent = (props: ServiceCardInfoProps) => {
	const { bgImage, title, subtitle, text, cardNumber } = props

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const imgLink = bgImage[cardNumber % 2 !== 0 ? 1 : 0]

	const [infoShowed, setInfoShown] = useState(false)

	const handleInfoHover = (): void => {
		setInfoShown(!infoShowed)
	}

	return (
		<CardContent
			sx={{
				minWidth: '100%',
				maxWidth: '100%',
				backgroundImage: `url("${imgLink}")`,
				backgroundSize: 'contain',
				backgroundPosition: cardNumber % 2 === 0 ? 'top 0 left 0' : 'top 0 right 0',
				backgroundRepeat: 'no-repeat',

				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'end',
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: cardNumber % 2 !== 0 ? 'flex-start' : 'flex-end',
					mx: 1,
				}}
			>
				<Button
					size="small"
					sx={{
						zIndex: 10,
						p: 0,
						mb: 0,
						minWidth: 0,
					}}
				>
					<SvgIcon
						component={infoIcon}
						fontSize="large"
						color="secondary"
						sx={{ zIndex: 10 }}
					/>
				</Button>
			</Box>
			<Box>
				<Typography
					variant="h5"
					component="div"
					sx={{
						textAlign: cardNumber % 2 !== 0 ? 'left' : 'right',
						maxWidth: 'content',
						fontWeight: 600,
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
				>
					{translate(title)}
				</Typography>
			</Box>
		</CardContent>
	)
}

export default ServiceCardContent
