import { useContext } from 'react'
import {
	Box,
	TextField,
	InputAdornment,
	SvgIcon,
	Typography,
	Button,
	Divider,
} from '@mui/material'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined'

import { useForm } from 'react-hook-form'
import contactSupportEmail from '../../services/contactSupport'
import { useAuthContext } from '../../../context/authContext'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import { grey, red } from '@mui/material/colors'
import { ReactComponent as SOSIcon } from '../../images/icons/SOS.svg'
import { LanguageContext } from '../../../context/languageContext'

type FormValues = {
	name: string
	phone: string
	text: string
}

type ContactSupportFormProps = {
	onClose: () => void;
}

export default function ContactSupportForm(props: ContactSupportFormProps) {
	const { onClose } = props

	const { user } = useAuthContext()
	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const {
		register,
		setValue,
		handleSubmit,
		formState: { errors },
	} = useForm<FormValues>({
		defaultValues: {
			name: '',
			phone: '',
			text: '',
		},
		delayError: 500,
		mode: 'onChange',
	})

	const onSubmit = handleSubmit(async (request: FormValues) => {
		const { name, phone, text } = request
		const email = user.email
		const response = await contactSupportEmail({
			email,
			name,
			phone,
			text,
		}, user.deploymentGroup.api)

		if (response?.status !== 200) {
			addError(`${translate(response?.code)}`)
		} else {
			setValue('name', '')
			setValue('phone', '')
			setValue('text', '')
			onClose()
			addSuccess(`${translate('success_contact_support_form')}`)
		}
	})

	return (
		<>
			<Box sx={{ m: 4 }}>
				<TextField
					{...register('name', {})}
					aria-label={`${translate('label_name_support_form')}`}
					sx={{
						width: '100%',
						height: '80px',
						'.MuiInputLabel-root': {
							color: '#757575 !important',
						},
					}}
					label={`${translate('label_name_support_form')}`}
					variant="standard"
					color="primary"
					focused
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<AccountCircleOutlinedIcon color="secondary" />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					{...register('phone', {
						pattern: {
							value: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
							message: `${translate('mistake_invalid_phone_field')}`,
						},
					})}
					aria-label={`${translate('label_phone_support_form')}`}
					error={errors?.phone ? true : false}
					helperText={errors?.phone ? errors?.phone.message : ''}
					sx={{
						width: '100%',
						height: '80px',
						'.MuiInputLabel-root': {
							color: '#757575 !important',
						},
					}}
					label={`${translate('label_phone_support_form')}`}
					type="phone"
					variant="standard"
					color="primary"
					focused
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<LocalPhoneOutlinedIcon color="secondary" />
							</InputAdornment>
						),
					}}
				/>
				<TextField
					{...register('text', {
						required: {
							value: true,
							message: `${translate('mistake_empty_text_field')}`,
						},
					})}
					aria-label={`${translate('label_text_support_form')}`}
					sx={{
						width: '100%',
						height: '80px',
						'.MuiInputLabel-root': {
							color: '#757575 !important',
						},
					}}
					label={`${translate('label_text_support_form')}`}
					multiline
					error={errors?.text ? true : false}
					helperText={errors?.text ? errors?.text.message : ''}
					variant="standard"
					color="primary"
					focused
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<EditNoteOutlinedIcon color="secondary" />
							</InputAdornment>
						),
						style: { fontSize: 14 },
					}}
				/>
			</Box>
			<Box>
				<Divider />
				<Button
					variant="text"
					sx={{
						py: 2,
						px: 4,
						color: grey[600],
						fontSize: 10,
						fontWeight: 300,
						backgroundColor: grey[50],
						'&.MuiButton-root': {
							borderRadius: '0px',
						},
						'& .MuiSvgIcon-fontSizeMedium': {
							fontSize: 40,
						},
						'&:hover': {
							backgroundColor: '#ff7e7e',
						},
					}}
					fullWidth
					startIcon={
						<SvgIcon
							sx={{
								fontSize: '8vh',
								ml: 2,
								color: red[500],
							}}
							component={SOSIcon}
						/>
					}
					onClick={onSubmit}
				>
					<Box sx={{ width: '100%', paddingX: 1 }}>
						<Typography
							variant="h5"
							color="black"
							align="left"
							fontFamily={'Barlow Condensed'}
							sx={{ fontWeight: 600 }}
						>
							{translate('button_help')}
						</Typography>
					</Box>
				</Button>
			</Box>
		</>
	)
}
