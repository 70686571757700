export function checkVersion(path: string, defaultVersion: string, search: string): string {
	const splittedPath = path.split('/')

	const versionPattern = /^v\d+$/
	if (!versionPattern.test(splittedPath[1])) {
		splittedPath.splice(1, 0, defaultVersion)
	}
	if (!splittedPath.includes(defaultVersion)) {
		splittedPath[1] = defaultVersion
	}
	const cleanedPath = splittedPath.filter((segment) => segment !== '')
	const newURL = `${cleanedPath.join('/')}${search}`

	return newURL
}
