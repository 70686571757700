export function decodeAtoB(encodedString: string) {
	let decoded
	try {
		decoded = atob(encodedString)
	} catch (error) {
		console.error('error')
		decoded = ''
	}
	return decoded
}
