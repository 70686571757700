import { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useCookies } from 'react-cookie'
import { Alert, Box, Button, Divider, Grid, Typography } from '@mui/material'
import suportButton from '../images/boton-soporte.png'
import { useAuthContext } from '../../context/authContext'
import { LanguageContext } from '../../context/languageContext'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { useStatusContext } from '../../context/statusContext'
import Header from '../components/common/Header'
import ProfileNavBar from '../components/navbars/ProfileNavBar'
import ContactSupportBar from '../components/navbars/ContactSupportBar'
import ServiceCard from '../components/cards/ServiceCard'
import { cardConfig } from '../utils/cardConfig'
import useSetAcademyUrlLogin from '../hooks/useSetAcademyUrlLogin'
import useUserServiceCards from '../hooks/useConfigServiceCards'
import useRemoveCookiesOnClose from '../hooks/useRemoveCookiesOnClose'
import useDecodeUserToken from '../hooks/useDecodeUserToken'
import { underMaintenance } from '../utils/underMaintenance'
import { ServiceData } from '../types/types.d'
import { useVersion } from '../../context/appVersionContext'
import useSetLRSCookie from '../hooks/useSetLRSCookie'

const Services = () => {
	const { user, addUser, removeUser } = useAuthContext()
	const [cookies, setCookie, removeCookie] = useCookies()
	const { appVersion } = useVersion()
	const { addError } = useErrorMessageContext()
	const { status } = useStatusContext()
	let navigate = useNavigate()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const { maintenance, formattedDate } = underMaintenance(language)

	const [userServices, setUserServices] = useState<ServiceData[]>([])

	const [academy, setAcademy] = useState({
		loginURL: '',
	})

	const [chargeBee, setChargeBee] = useState({
		loginURL: '',
	})

	useEffect(() => {
		useSetLRSCookie(user, setCookie, addError, translate)
	}, [user])

	useEffect(() => {
		useSetAcademyUrlLogin(user, status, setAcademy, addError, translate)
	}, [user])

	useEffect(() => {
		const autologin = async () => {
			await useDecodeUserToken(cookies, addUser, addError, navigate, translate)
		}
		autologin()
	}, [])

	useEffect(() => {
		useUserServiceCards(user, academy, cardConfig, chargeBee, setUserServices)
	}, [user, academy])

	useRemoveCookiesOnClose(user, removeUser, cookies, removeCookie)

	const [openMyProfile, setOpenMyProfile] = useState(false)

	const handleClickMyProfile = () => {
		setOpenMyProfile(!openMyProfile)
	}

	const [openSupportForm, setOpenSupportForm] = useState(false)

	const handleClickSupportForm = () => {
		if (status.connection !== 'online') {
			return addError(`${translate('error_connection')}`)
		}
		setOpenSupportForm(!openSupportForm)
	}

	return (
		<>
			<Grid
				sx={{
					flexGrow: 1,
					height: { xs: '100%', sm: '100%', md: '100%', lg: '80vh', xl: '80vh' },
				}}
				container
				spacing={3.5}
				alignItems="stretch"
				alignContent="stretch"
			>
				<Header handleClick={handleClickMyProfile} />
				<Box component={'div'} sx={{ display: 'none' }}>
					<ProfileNavBar variant="permanent" open={true} />
				</Box>
				<Box component={'div'} sx={{ display: 'none' }}>
					<ProfileNavBar
						variant="temporary"
						open={openMyProfile}
						onClose={handleClickMyProfile}
					/>
				</Box>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
					<Grid container justifyContent="center" spacing={0}>
						<Box
							sx={{
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								mb: 3,
							}}
						>
							{maintenance && (
								<Alert severity="warning"
									sx={{
										position: 'relative',
										zIndex: 1,
										width: 'auto',
										maxWidth: 700,
										fontSize: {
											xs: 10,
											sm: 10,
											md: 14,
											lg: 14,
											xl: 14
										},
									}}>
									<strong>{`${translate('title_maintenance')}`}</strong>
									<br />
									{`${translate('text_maintenance_pt1')} ${formattedDate}. ${translate(
										'text_maintenance_pt3'
									)}`}
									<br />
									{`${translate('text_maintenance_pt2')}`}
								</Alert>
							)}
						</Box>
						<Box
							sx={{
								mt: {
									xs: maintenance ? 3 : 0,
									sm: maintenance ? 3 : 0,
									md: maintenance ? 3 : '40px',
									lg: maintenance ? 3 : '100px',
									xl: maintenance ? 3 : '100px',
								},
								backgroundColor: 'inherit',
								display: 'grid',
								gridTemplateColumns: {
									xs: '1fr',
									sm: '1fr',
									md: '1fr 1fr',
									lg: '1fr 1fr',
									xl: '1fr 1fr',
								},
								gap: 3,
								width: { xs: 'auto', sm: 'auto', md: 'auto', lg: 'auto', xl: 'auto' },
								height: '100%',
							}}
						>
							{userServices.length !== 0 &&
								userServices.map((services: ServiceData) => (
									<Box
										key={Math.random().toString().split('.')[1]}
										sx={{
											width: '20vw',
											minWidth: {
												xs: '280px',
												sm: '300px',
												md: '280px',
												lg: '360px',
												xl: '360px',
											},
										}}
									>
										<ServiceCard {...services} />
									</Box>
								))}
						</Box>
					</Grid>
				</Grid>
				<Grid
					container
					direction="row"
					justifyContent="center"
					wrap="nowrap"
					alignItems="flex-end"
					sx={{ mt: '2vh' }}
				>
					<Box component={'div'} sx={{ display: 'none' }}>
						<ContactSupportBar
							open={openSupportForm}
							onClose={handleClickSupportForm}
						/>
					</Box>
					<Box
						sx={{
							zIndex: '0 !important',
							width: { md: '100%', lg: '100%', xl: '100%' },
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'stretch',
							alignItems: 'center !important',
						}}
					>
						<Divider
							variant="middle"
							sx={{
								color: '#35e398',
								borderColor: '#35e398',
								width: { xs: '300px', sm: '340px', md: '100%', lg: '40%', xl: '40%' },
								borderWidth: 1,
								marginLeft: {
									xs: '40px',
									sm: '50px',
									md: '50px',
									lg: '20px',
									xl: '20px',
								},
							}}
						/>
						<Typography
							variant="h5"
							sx={{
								mt: 2,
								color: 'white',
								fontSize: {
									xs: '10px',
									sm: '12px',
									md: '24px',
									lg: '24px',
									xl: '24px',
								},
								marginLeft: {
									xs: '30px',
									sm: '30px',
									md: '50px',
									lg: '20px',
									xl: '20px',
								},
								marginX: 1,
								textAlign: 'center',
							}}
						>
							{translate('text_service_pt1')}
						</Typography>
						<Typography
							variant="caption"
							sx={{
								color: 'white',
								fontSize: { xs: '8px', sm: '10px', md: '12px', lg: '12px', xl: '12px' },
								marginLeft: {
									xs: '40px',
									sm: '40px',
									md: '50px',
									lg: '20px',
									xl: '20px',
								},
								marginX: 2,
								textAlign: 'center',
							}}
						>
							{translate('text_service_pt2')}
						</Typography>
					</Box>
				</Grid>
				<Box
					sx={{
						position: {
							xs: 'relative',
							sm: 'relative',
							md: 'relative',
							lg: 'absolute',
							xl: 'absolute',
						},
						right: { xs: 0, sm: 0, md: 0, lg: 16, xl: 16 },
						bottom: { xs: 0, sm: 0, md: 0, lg: 16, xl: 16 },
						top: { xs: 30, sm: 20, md: 20, lg: 16, xl: 16 },
						display: 'flex',
						justifyContent: {
							xs: 'center',
							sm: 'center',
							md: 'center',
							lg: 'flex-end',
							xl: 'flex-end',
						},
						alignItems: {
							xs: 'center',
							sm: 'center',
							md: 'center',
							lg: 'flex-end',
							xl: 'flex-end',
						},
						width: { xs: '100%', sm: '100%', md: '100%', lg: 'auto', xl: 'auto' },
						marginLeft: {
							xs: '50px',
							sm: '40px',
							md: '50px',
							lg: '20px',
							xl: '20px',
						},
					}}
				>
					<Button
						size="large"
						variant="contained"
						sx={{
							height: '100px',
							width: '80px',
							borderRadius: '40px !important',
							right: '1vw',
						}}
						onClick={handleClickSupportForm}
					>
						<Box
							component="img"
							src={`${suportButton}`}
							title="SuportButton"
							sx={{
								display: 'block',
								width: 'auto',
								height: '100px',
							}}
						/>
					</Button>
				</Box>
				<Box
					sx={{
						position: {
							xs: 'relative',
							sm: 'relative',
							md: 'relative',
							lg: 'absolute',
							xl: 'absolute',
						},
						left: { xs: 0, sm: 0, md: 0, lg: 16, xl: 16 },
						bottom: { xs: 0, sm: 0, md: 0, lg: 16, xl: 16 },
						top: { xs: 30, sm: 30, md: 30, lg: 16, xl: 16 },
						display: 'flex',
						justifyContent: {
							xs: 'center',
							sm: 'center',
							md: 'center',
							lg: 'flex-start',
							xl: 'flex-start',
						},
						alignItems: {
							xs: 'center',
							sm: 'center',
							md: 'center',
							lg: 'flex-end',
							xl: 'flex-end',
						},
						width: { xs: '100%', sm: '100%', md: '100%', lg: 'auto', xl: 'auto' },
						marginLeft: {
							xs: '40px',
							sm: '30px',
							md: '30px',
							lg: '20px',
							xl: '20px',
						},
						marginTop: { xs: '5px', sm: '5px' },
					}}
				>
					<Typography variant="caption" sx={{ color: 'white' }}>
						{appVersion.portal}
					</Typography>
				</Box>
			</Grid>
		</>
	)
}

export default Services
