import { FC } from 'react'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'

type HamburgerMenuButtonProps = {
    onClick: () => void
}

const HamburgerMenuButton: FC<HamburgerMenuButtonProps> = ({ onClick }) => {
    return (
        <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={onClick}
            sx={{marginLeft:{xxs:'2px', xs:'0px'}}}
        >
            <MenuIcon sx={{ fontSize: '24px' }} />
        </IconButton>
    )
}

export default HamburgerMenuButton
