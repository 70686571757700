import { useState, useEffect, useContext } from 'react'
import { useParams, Navigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import { useErrorMessageContext } from '../../context/errorMessageContext'
import { LanguageContext } from '../../context/languageContext'
import { userFind } from '../services/userFind'
import ResetPassForm from '../components/forms/ResetPassForm'
import { decodeResetCodeToken } from '../utils/JWTE'
import { UserFindRequest } from '../types/types.d'


function PasswordRecovery() {
	const { token } = useParams()
	const { addError, error } = useErrorMessageContext()
	const [userData, setUserData] = useState<UserFindRequest>()
	const [passwordChangeCode, setPasswordChangeCode] = useState()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	useEffect(() => {
		const decodedToken = (async function (token: string) {
			const { status, data, code } = await decodeResetCodeToken(token)
			if (status !== 200) {
				addError(`${translate(code!)}`)
			} else if (typeof data !== 'boolean' && typeof data !== 'string' && data !== null) {
				const { userId, userResetCode } = data as { userId: string; userResetCode: undefined };
				findUserDataById(userId);
				setPasswordChangeCode(userResetCode);
			}
		})(token!)
	}, [])

	const findUserDataById = (id) => {
		const searchUser = (async function (id) {
			const { status, data, code } = await userFind(id)
			if (status !== 200) {
				addError(`${translate(code)}`)
			} else {
				setUserData(data)
			}
		})(id!)
	}

	return (
		<Grid item xs={12}>
			<Grid container justifyContent="center">
				{error.message !== '' && <Navigate to="../" replace={true} />}
				<ResetPassForm user={userData!} resetCode={passwordChangeCode!} />
			</Grid>
		</Grid>
	)
}

export default PasswordRecovery
