import LearningLockerLogin from '../services/learningLockerLogin'

const useSetLRSCookie = (user, setCookie, addError, translate) => {
	const setLRSCookieAsync = async () => {
		if (user.email !== '' && user.role.services.LRS) {
			const { status, data, code } = await LearningLockerLogin(
				user.email,
				user.deploymentGroup.api
			)
			if (status !== 200) {
				addError(`${translate(code)}`)
			} else {
				setCookie(`access_token_user_${data.userId}`, data.token, {
					path: '/',
					domain: 'ludusservers.com',
				})
			}
		}
	}

	setLRSCookieAsync()
}

export default useSetLRSCookie
