import { Box, Card, CardContent, CardMedia, Divider, Grid, Typography } from '@mui/material';
import { FC, useContext } from 'react';
import SupportCardButtonError from '../components/buttons/SupportCardButtonError';
import LogoSmall from '../images/imagotipo.png'
import TriangleImage from '../images/icon-error-up-right.png'
import { LanguageContext } from '../../context/languageContext'

type ErrorBoundaryComponentProps = {
    error?: Error;
    resetErrorBoundary?: () => void;
}

const ErrorPage: FC<ErrorBoundaryComponentProps> = ({ error, resetErrorBoundary }) => {
    const {
        state: { language },
        dispatch: { setLanguage, translate },
    } = useContext(LanguageContext)
    return (
        <>
                <Grid item xs={12}>
                    <Grid container justifyContent="center">
                        <Box component={'div'}>
                            <Card
                                sx={{
                                    minWidth: { xs: 310, sm: 340 },
                                    maxWidth: 640,
                                    borderRadius: '15px',
                                    boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
                                    position: 'relative',
                                }}
                            >
                                <CardContent>
                                    <CardMedia
                                        component="img"
                                        image={`${LogoSmall}`}
                                        title="LogoSmall"
                                        sx={{
                                            margin: 'auto',
                                            display: 'block',
                                            width: 'auto',
                                            maxHeight: '100px',
                                        }}
                                    />

                                    <CardMedia
                                        component="img"
                                        image={TriangleImage}
                                        alt="Triángulo"
                                        sx={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                            maxHeight: {
                                                xs: '60px',
                                                sm: '70px',
                                                md: '80px',
                                                lg: '90px',
                                                xl: '90px'
                                            },
                                            maxWidth: {
                                                xs: '60px',
                                                sm: '70px',
                                                md: '80px',
                                                lg: '90px',
                                                xl: '90px'
                                            },
                                            margin: 1.5
                                        }}
                                    />


                                    <Divider variant="middle" sx={{
                                        mb: 2,
                                        mx: {
                                            xs: 4,
                                            sm: 6,
                                            md: 16,
                                            lg: 20,
                                        },
                                    }} />
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        color="text.secondary"
                                        gutterBottom
                                    >
                                        <strong>{translate('title_error_message')}</strong>
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        align="center"
                                        sx={{ mt: 1, padding: 1 }}
                                        color="text.secondary"
                                        fontSize={{
                                            xs: 10,
                                            sm: 12,
                                            md: 18,
                                            lg: 18,
                                            xl: 18
                                        }}
                                    >
                                        {translate('subtitle_error_message')}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        align="center"
                                        sx={{ padding: 1 }}
                                        color="text.secondary"
                                        fontSize={{
                                            xs: 10,
                                            sm: 12,
                                            md: 18,
                                            lg: 18,
                                            xl: 18
                                        }}
                                    >
                                        {translate('text_error_message_pt1')}
                                    </Typography>
                                </CardContent>
                                <SupportCardButtonError />
                            </Card>
                        </Box>
                    </Grid>
                </Grid>
        </>
    );
};

export default ErrorPage;
