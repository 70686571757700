import { SyntheticEvent, useContext, KeyboardEvent } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	Card,
	CardContent,
	CardMedia,
	IconButton,
	Typography,
	TextField,
	InputAdornment,
	SvgIcon,
	CardActions,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import { ReactComponent as emailIcon } from '../../images/icons/email.svg'
import LogoSmall from '../../images/logo-ludus.png'
import { useErrorMessageContext } from '../../../context/errorMessageContext'
import { useSuccessMessageContext } from '../../../context/successMessageContext'
import { LanguageContext } from '../../../context/languageContext'
import UserForgotPass from '../../services/userForgotPass'
import { GradientButton } from '../buttons/GradientButton'
import SupportCardButton from '../buttons/SupportCardButton'

type FormProps = {
	onClose: (event: SyntheticEvent | Event) => void
}

type FormValues = {
	email: string
}

export default function ForgotPassForm({ onClose }: FormProps) {
	const { addError } = useErrorMessageContext()
	const { addSuccess } = useSuccessMessageContext()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const {
		register,
		handleSubmit,
		resetField,
		formState: { errors },
	} = useForm<FormValues>({ delayError: 1000, mode: 'onChange' })

	let navigate = useNavigate()

	const onSubmit = handleSubmit(async (request, response) => {
		const { status, code } = await UserForgotPass(request)
		resetField('email')

		if (status === 200) {
			addSuccess(`${translate('success_request')}`)
			setTimeout(() => {
				return navigate(0)
			}, 3000)
		} else {
			return addError(`${translate(code)}`)
		}
	})

	const handleClose = (event: SyntheticEvent | Event) => {
		onClose(event)
	}

	const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') onSubmit()
	}

	return (
		<Card
			sx={{
				minWidth: { xs: 300, sm: 360, md: 480, lg: 480 },
				maxWidth: 480,
				borderRadius: '15px',
				boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
			}}
			onKeyDown={handleKeyPress}
		>
			<IconButton
				id="close"
				aria-label="delete"
				color="primary"
				size="large"
				sx={{ position: 'absolute', zIndex: 10 }}
				onClick={handleClose}
			>
				<ArrowCircleLeftIcon />
			</IconButton>
			<CardContent sx={{ mx: 3 }}>
				<CardMedia
					component="img"
					image={`${LogoSmall}`}
					title="LogoSmall"
					sx={{
						margin: 'auto',
						my: 3,
						display: 'block',
						width: '100%',
						maxHeight: '100px',
					}}
				/>

				<Typography
					variant="h5"
					align="center"
					sx={{ fontWeight: 600 }}
					color="black"
				>
					<strong>{`${translate('title_forgot_pass')}`}</strong>
				</Typography>
				<Typography
					variant="body2"
					align="center"
					sx={{ mt: 1, mb: 3 }}
					color="#757575"
				>
					{`${translate('text_forgot_pass_pt1')}`}
					<strong> {`${translate('text_forgot_pass_pt2')}`} </strong>
					{`${translate('text_forgot_pass_pt3')}`}
				</Typography>
				<TextField
					{...register('email', {
						required: {
							value: true,
							message: `${translate('mistake_empty_email_field')}`,
						},
						pattern: {
							value:
								/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
							message: `${translate('mistake_invalid_email_field')}`,
						},
					})}
					aria-label={`${translate('label_email')}`}
					fullWidth
					placeholder={`${translate('label_email')}`}
					sx={{
						my: 1,
						'& .MuiOutlinedInput-root': {
							borderRadius: '50px',
							backgroundColor: grey[200],
							border:
								errors?.email?.type === 'pattern' || errors?.email?.type === 'required'
									? 'solid red 4px'
									: null,
						},
						'& .MuiOutlinedInput-input': {
							color: grey[700],
							'&:hover fieldset': {
								borderColor: 'green',
							},
						},
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<SvgIcon component={emailIcon} color="secondary" />
							</InputAdornment>
						),
					}}
				/>
				{errors?.email && (
					<Typography
						variant="caption"
						align="center"
						color="red"
						sx={{ ml: 3, fontFamily: 'Inter' }}
					>
						{errors.email.message}
					</Typography>
				)}
			</CardContent>
			<CardActions sx={{ justifyContent: 'flex-start', mx: 4, mb: 4 }}>
				<GradientButton
					fullWidth
					sx={{ color: 'white', padding: '14px 16px' }}
					variant="contained"
					onClick={onSubmit}
					onKeyDown={(e) => (e.code === 'Enter' ? onSubmit : null)}
				>
					{`${translate('button_send_forgot_pass')}`}
				</GradientButton>
			</CardActions>
			<SupportCardButton subject="Recuperar contraseña" />
		</Card>
	)
}
