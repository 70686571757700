import axios from 'axios'

export type ForgotPassRequest = {
	email: string
}

export default async function UserForgotPass(request: ForgotPassRequest) {
	const { email } = request

	return await axios
		.post(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/forgotpassword`,
			{ email },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
					'x-api-version': process.env.REACT_APP_LOGIN_API_VERSION
				},
			}
		)
		.then((response) => response.data)
		.catch((error) => {
			console.error(error)
			return error.response.data
		})
}
