import axios from 'axios'

export type SigninUserResponse = {
	email: string
	password: string
	remember: boolean
}

export type SigninUserRequest = {
	id: string
	verificationCode: string
	password: string
	passwordConfirmation: string
}

export type FindUserResponse = {
	_id: string
	email: string
	firstName: string
	lastName: string
	role: string
	verified: boolean
	verificationCode: string
}

export async function userFind(id: string) {
	return await axios
		.get<FindUserResponse>(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/verification/${id}`,
			{
				headers: {
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					'x-api-version': process.env.REACT_APP_LOGIN_API_VERSION
				},
			}
		)
		.then((response) => {
			return response.data
		})

		.catch((error) => {
			console.error(error)
			return error.response.data
		})
}

export async function userFindServices(id: string) {
	return await axios
		.get<FindUserResponse>(
			`${process.env.REACT_APP_AUTH_API_URL}/api/users/${id}/services`,
			{
				headers: {
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
				},
			}
		)
		.then((response) => response.data)

		.catch((error) => {
			console.error(error)
			return error.response.data
		})
}

export async function userCheckId(id: string) {
	return await axios
		.get(`${process.env.REACT_APP_AUTH_API_URL}/api/users/check/${id}`, {
			headers: {
				Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
			},
		})
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			throw error.response.data
		})
}
