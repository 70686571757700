import {
	SyntheticEvent,
	useContext,
	useEffect,
	useState,
	MouseEvent,
	KeyboardEvent,
} from 'react'
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import {
	Box,
	Button,
	FormControlLabel,
	Typography,
	TextField,
	FormGroup,
	Checkbox,
	SvgIcon,
	OutlinedInput,
	IconButton,
	Card,
	InputAdornment,
	CardActions,
	CardContent,
	CardMedia,
	Alert,
} from '@mui/material'
import { grey } from '@mui/material/colors'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { ReactComponent as emailIcon } from '../../images/icons/email.svg'
import { ReactComponent as passwordIcon } from '../../images/icons/Password.svg'
import LogoSmall from '../../images/logo-ludus.png'
import { useStatusContext } from '../../../context/statusContext'
import { LanguageContext } from '../../../context/languageContext'
import { useAuthContext } from '../../../context/authContext'
import { GradientButton } from '../buttons/GradientButton'
import SupportCardButton from '../buttons/SupportCardButton'
import useUserAuthentication from '../../hooks/useUserLogin'
import useTokenAuthentication from '../../hooks/useJWETokenAuth'
import useLogout from '../../hooks/useLogout'
import { useCookies } from 'react-cookie'
import { decodeAtoB } from '../../utils/decodeAtoB'
import { underMaintenance } from '../../utils/underMaintenance'

type FormProps = {
	onForgotPass: (event: SyntheticEvent | Event) => void
}

type RequestProps = {
	email: string
	password: string
	remember: boolean
}

function LoginForm({ onForgotPass }: FormProps) {
	const { removeUser } = useAuthContext()
	const { userNameParam, JWEToken, hubLanguage } = useParams()
	const [userEmail, setUserEmail] = useState('')
	const { authenticateWithToken } = useTokenAuthentication()
	const handleAuthentication = useUserAuthentication()
	const { status } = useStatusContext()
	const [cookies, removeCookie] = useCookies()
	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)
	const [showPassword, setShowPassword] = useState(false)
	const [checkboxStatus, setCheckboxStatus] = useState(true)
	const [rememberMeCheck, setRememberCheck] = useState(true)
	const [readOnly, setReadOnly] = useState(true)

	const { maintenance, formattedDate } = underMaintenance(language)

	const {
		register,
		handleSubmit,
		setValue,
		resetField,
		formState: { errors, submitCount },
	} = useForm<{
		email: string
		password: string
		remember: boolean
	}>({ delayError: 1000, mode: 'onChange' })

	const onSubmit = handleSubmit(async (request: RequestProps) => {
		{
			await handleAuthentication(request, resetField, rememberMeCheck)
		}
	})

	useEffect(() => {
		if (JWEToken) {
			setLanguage(hubLanguage)
			authenticateWithToken(JWEToken)
		}
	}, [JWEToken])

	useEffect(() => {
		setCheckboxStatus(status.connection === 'offline')
		setRememberCheck(status.connection !== 'offline')
	}, [status.connection])

	const handleForgotPass = (event: SyntheticEvent | Event) => {
		onForgotPass(event)
	}

	useEffect(() => {
		if (cookies.AuthToken?.email) {
			const email = cookies.AuthToken.email
			const decodedEmail = decodeAtoB(email)
			setUserEmail(decodedEmail)
		}
	}),
		[cookies]

	useEffect(() => {
		if (userEmail !== '') {
			setValue('email', userEmail)
			setValue('password', '')
		}
	}, [userEmail])

	const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') onSubmit()
	}

	const handleChange = () => {
		if (cookies.AuthToken) {
			useLogout(cookies, removeCookie, removeUser)
		}
	}
	return (
		<Card
			sx={{
				minWidth: { xs: 300, sm: 360, md: 480, lg: 480 },
				maxWidth: 480,
				borderRadius: '15px',
				boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
			}}
			onKeyDown={handleKeyPress}
		>
			<CardContent sx={{ mb: -1 }}>
				<CardMedia
					component="img"
					image={`${LogoSmall}`}
					title="LogoSmall"
					sx={{
						margin: 'auto',
						mt: 3,
						display: 'block',
						width: '100%',
						maxHeight: '100px',
					}}
				/>
				<Box sx={{ mx: 3 }}>
					<Typography
						variant="h5"
						align="center"
						sx={{ my: 3 }}
						color="text.secondary"
					>
						{userNameParam !== undefined ? (
							`${translate('title_welcome')} ${userNameParam}`
						) : submitCount !== 0 ? (
							`${translate('title_welcome_II')} `
						) : (
							<Box>
								<Typography
									variant="h5"
									align="center"
									sx={{ fontWeight: 600 }}
									color="black"
								>
									{`${translate('title_login')}`}
								</Typography>
								<Typography
									variant="body2"
									align="center"
									sx={{ mt: 1, mb: 3 }}
									color="#757575"
								>
									{`${translate('text_login')}`}
								</Typography>
							</Box>
						)}
					</Typography>
					{maintenance && (
						<Alert severity="warning"
							sx={{
								mt: 1,
								mb: 3,
								width: '100%',
								height: '100%',
								fontSize: {
									xs: 10,
									sm: 12,
									md: 14,
									lg: 14,
									xl: 14
								},
							}}>
							<strong>{`${translate('title_maintenance')}`}</strong>
							<br />
							{`${translate('text_maintenance_pt1')} ${formattedDate}. ${translate(
								'text_maintenance_pt3'
							)}`}
							<br />
							{`${translate('text_maintenance_pt2')}`}
						</Alert>
					)}

					<TextField
						{...register('email', {
							required: {
								value: true,
								message: `${translate('mistake_empty_email_field')}`,
							},

							pattern: {
								value:
									/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
								message: `${translate('mistake_invalid_email_field')}`,
							},
							onChange: () => {
								handleChange()
							},
						})}
						onFocus={(e) => setReadOnly(false)}
						aria-label={`${translate('label_email')}`}
						fullWidth
						placeholder={`${translate('label_email')}`}
						sx={{
							my: 1,
							'& .MuiOutlinedInput-root': {
								borderRadius: '50px',

								border:
									errors?.email?.type === 'pattern' || errors?.email?.type === 'required'
										? 'solid red 4px'
										: null,
							},
							'& .MuiOutlinedInput-input': {
								color: grey[700],
								'&:hover fieldset': {
									borderColor: 'green',
								},
							},
						}}
						InputProps={{
							startAdornment: (
								<InputAdornment position="start">
									<SvgIcon component={emailIcon} color="secondary" />
								</InputAdornment>
							),
						}}
					/>
					{errors?.email && (
						<Typography
							variant="caption"
							align="center"
							color="red"
							sx={{ ml: 3, fontFamily: 'Inter' }}
						>
							{errors.email.message}
						</Typography>
					)}

					<OutlinedInput
						{...register('password', {
							required: {
								value: true,
								message: `${translate('mistake_empty_pass_field')}`,
							},
							pattern: {
								value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
								message: `${translate('mistake_invalid_pass_field')}`,
							},
						})}
						aria-label={`${translate('label_password')}`}
						readOnly={readOnly}
						onFocus={(e) => setReadOnly(false)}
						fullWidth
						placeholder={`${translate('label_password')}`}
						type={showPassword ? 'text' : 'password'}
						sx={{
							mt: 1,
							border:
								errors?.password?.type === 'pattern' ||
									errors?.password?.type === 'required'
									? 'solid red 4px'
									: null,
							borderRadius: '50px',

							'& .MuiOutlinedInput-input': {
								color: grey[700],
								'&:hover fieldset': {
									borderColor: 'green',
								},
							},
						}}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={() => setShowPassword((show) => !show)}
									onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
										event.preventDefault()
									}}
									edge="end"
									sx={{
										color: grey[500],
										mr: '1px',
									}}
								>
									{showPassword ? <VisibilityOff /> : <Visibility />}
								</IconButton>
							</InputAdornment>
						}
						startAdornment={
							<InputAdornment position="start">
								<SvgIcon component={passwordIcon} color="secondary" />
							</InputAdornment>
						}
					/>
					{errors?.password && (
						<Typography
							variant="caption"
							align="center"
							color="red"
							sx={{ ml: 3, fontFamily: 'Inter' }}
						>
							{errors.password.message}
						</Typography>
					)}
				</Box>
			</CardContent>
			<CardActions sx={{ justifyContent: 'flex-start', mx: 8 }}>
				<FormGroup>
					<FormControlLabel
						{...register('remember')}
						control={
							<Checkbox
								defaultChecked
								disabled={checkboxStatus}
								checked={rememberMeCheck}
							/>
						}
						label={`${translate('label_remember_me')}`}
						sx={{
							fontSize: '0.875rem',
							color: grey[600],
							'& .MuiTypography-root': {
								fontSize: {
									xs: '0.77rem',
									sm: '1rem',
								},
							},
						}}
						onChange={() => setRememberCheck(!rememberMeCheck)}
					/>
				</FormGroup>
			</CardActions>
			<CardActions sx={{ justifyContent: 'flex-start', mx: 4, mb: 4 }}>
				<GradientButton
					fullWidth
					sx={{ color: 'white', padding: '14px 16px' }}
					variant="contained"
					onClick={onSubmit}
				>
					{`${translate('button_send_login')}`}
				</GradientButton>
			</CardActions>
			{submitCount !== 0 && <SupportCardButton />}
			{status.connection !== 'offline' && (
				<Box
					sx={{
						backgroundColor: grey[300],
					}}
				>
					<Button
						variant="text"
						onClick={handleForgotPass}
						sx={{
							p: 2,
							color: grey[700],
							'&.MuiButton-root': {
								borderRadius: '0px',
							},
							'&:hover': {
								backgroundColor: '#9e9e9e',
								color: '#fafafa',
							},
						}}
						fullWidth
						id="forgotPass"
					>
						{`${translate('button_forgot_pass')}`}
					</Button>
				</Box>
			)}
		</Card>
	)
}

export default LoginForm
