import { useContext, useEffect, useState, MouseEvent, KeyboardEvent } from 'react'
import { useForm } from 'react-hook-form'
import {
	Typography,
	Divider,
	SvgIcon,
	OutlinedInput,
	IconButton,
	Card,
	InputAdornment,
	CardActions,
	CardContent,
	CardMedia,
	Box,
} from '@mui/material'
import grey from '@mui/material/colors/grey'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { ReactComponent as passwordIcon } from '../../images/icons/Password.svg'
import LogoSmall from '../../images/logo-ludus.png'
import { LanguageContext } from '../../../context/languageContext'
import { GradientButton } from '../buttons/GradientButton'
import SupportCardButton from '../buttons/SupportCardButton'
import useAccountVerification from '../../hooks/useAccountVerificarion'
import { UserData } from '../../types/types.d'

type VerifyAccountFormProps = {
	user: {
		id: number
		email: string
		firstName: string
		lastName: string
		role: string
		verified: boolean
		verificationCode: string
	}
	services: UserData['role']['services']
	setCountDown: (countDown: boolean) => void;
}

type FormValues = {
	password: string
	passwordConfirmation: string
}

function VerifyAccountForm(props: VerifyAccountFormProps) {
	const { user, services, setCountDown } = props

	const [showPassword, setShowPassword] = useState(false)

	const { verificationStatus, verifyUserAccounts } = useAccountVerification()

	const {
		state: { language },
		dispatch: { setLanguage, translate },
	} = useContext(LanguageContext)

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors, submitCount },
	} = useForm<FormValues>({ delayError: 1000, mode: 'onChange' })

	const onSubmit = handleSubmit(async (request: FormValues) => {
		verifyUserAccounts(request, user, services)
	})

	const handleKeyPress = (event: KeyboardEvent<HTMLDivElement>) => {
		if (event.key === 'Enter') onSubmit()
	}

	useEffect(() => {
		if (verificationStatus !== '') {
			setCountDown(true)
		}
	}, [verificationStatus])

	return (
		<Card
			sx={{
				minWidth: 275,
				maxWidth: 480,
				borderRadius: '15px',
				boxShadow: 'rgba(0, 0, 0, 0.30) 2px 2px 3px',
			}}
			onKeyDown={handleKeyPress}
		>
			<CardContent sx={{ mb: -1 }}>
				<CardMedia
					component="img"
					image={`${LogoSmall}`}
					title="LogoSmall"
					sx={{
						margin: 'auto',
						mt: 3,
						display: 'block',
						width: '100%',
						maxHeight: '100px',
					}}
				/>
				<Typography variant="h5" align="center" color="text.secondary">
					<strong>{`${translate('title_welcome')}`}</strong>
				</Typography>
				<Divider variant="middle" sx={{ mx: 20 }} />
				<Typography
					variant="body2"
					align="center"
					sx={{ mt: 1, mb: 3 }}
					color="#757575"
				>
					{user !== undefined ? (
						<Box sx={{ mx: 3 }}>
							<Typography
								variant="h5"
								align="center"
								sx={{ fontWeight: 600 }}
								color="black"
							>
								{`${translate('title_confirm_pass_welcome_pt1')} ${user.firstName}`}
							</Typography>

							<Typography
								variant="body2"
								align="center"
								sx={{ mt: 1, mb: 3 }}
								color="#757575"
							>
								{`${translate('title_confirm_pass_welcome_pt2')}`}
							</Typography>
						</Box>
					) : (
						submitCount !== 0 && `${translate('title_confirm_pass_try_again')}`
					)}
				</Typography>

				<OutlinedInput
					{...register('password', {
						required: {
							value: true,
							message: `${translate('mistake_empty_pass_field')}`, 
						},
						maxLength: {
							value: 20,
							message: `${translate('mistake_long_pass_field')}`, 
						},
						minLength: {
							value: 6,
							message: `${translate('mistake_short_pass_field')}`, 
						},
						pattern: {
							value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/,
							message: `${translate('mistake_unsafe_pass_field')}`, 
						},
					})}
					aria-label={`${translate('label_password')}`}
					fullWidth
					placeholder={`${translate('label_password')}`}
					type={showPassword ? 'text' : 'password'}
					sx={{
						mt: 1,
						border: errors?.password?.type === 'pattern' ? 'solid red 4px' : null,
						borderRadius: '50px',
						backgroundColor: grey[200],
						'& .MuiOutlinedInput-input': {
							color: grey[700],
							'&:hover fieldset': {
								borderColor: 'green',
							},
						},
					}}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword((show) => !show)}
								onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
									event.preventDefault()
								}}
								edge="end"
								sx={{
									color: grey[500],
									mr: '1px',
								}}
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					startAdornment={
						<InputAdornment position="start">
							<SvgIcon component={passwordIcon} color="secondary" />
						</InputAdornment>
					}
				/>
				{errors?.password && (
					<Typography
						variant="caption"
						align="center"
						color="red"
						sx={{ ml: 3, fontFamily: 'Inter' }}
					>
						{errors.password.message}
					</Typography>
				)}

				<OutlinedInput
					{...register('passwordConfirmation', {
						required: {
							value: true,
							message: `${translate('mistake_empty_confirm_pass_field')}`,
						},
						validate: (value) => value === watch('password'),
					})}
					aria-label={`${translate('label_confirm_pass')}`}
					fullWidth
					placeholder={`${translate('label_confirm_pass')}`}
					type={showPassword ? 'text' : 'password'}
					sx={{
						mt: 1,
						border:
							errors?.passwordConfirmation?.type === 'validate'
								? 'solid red 4px'
								: null,
						borderRadius: '50px',
						backgroundColor: grey[200],
						'& .MuiOutlinedInput-input': {
							color: grey[700],
							'&:hover fieldset': {
								borderColor: 'green',
							},
						},
					}}
					endAdornment={
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => setShowPassword((show) => !show)}
								onMouseDown={(event: MouseEvent<HTMLButtonElement>) => {
									event.preventDefault()
								}}
								edge="end"
								sx={{
									color: grey[500],
									mr: '1px',
								}}
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					}
					startAdornment={
						<InputAdornment position="start">
							<SvgIcon component={passwordIcon} color="secondary" />
						</InputAdornment>
					}
				/>
				{errors?.passwordConfirmation?.message && (
					<Typography
						variant="caption"
						align="center"
						color="red"
						sx={{ ml: 3, fontFamily: 'Inter' }}
					>
						{errors.passwordConfirmation.message}
					</Typography>
				)}
				{errors?.passwordConfirmation?.type === 'validate' && (
					<Typography
						variant="caption"
						align="center"
						color="red"
						sx={{ ml: 3, fontFamily: 'Inter' }}
					>
						{`${translate('mistake_not_match_confirm_pass_field')}`}
					</Typography>
				)}
			</CardContent>
			<CardActions sx={{ justifyContent: 'flex-start', mx: 4, mb: 4 }}>
				<GradientButton
					fullWidth
					sx={{ color: 'white', padding: '14px 16px' }}
					variant="contained"
					onClick={onSubmit}
				>
					{`${translate('button_send_confirm_pass')}`}
				</GradientButton>
			</CardActions>
			{submitCount !== 0 && <SupportCardButton />}
		</Card>
	)
}

export default VerifyAccountForm
