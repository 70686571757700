const useUserServiceCards = (
	user,
	academy,
	cardConfig,
	chargeBee,
	setUserServices
) => {
	const userServiceCards = []
	let cardNumber = 0

	for (const [key, value] of Object.entries(user.role.services)) {
		if (value) {
			cardNumber += 1
			switch (key) {
				case 'VrAcademy':
					userServiceCards.push({
						...cardConfig['VrAcademy'],
						isInMaintenance: cardConfig['VrAcademy'].isInMaintenance,
						link:
							academy.loginURL !== ''
								? academy.loginURL
								: 'https://vracademy.ludusglobal.com/acceso.php?redir=%2Fcampus%2F',
						cardNumber: cardNumber,
					})
					break
				case 'Shop':
					userServiceCards.push({
						...cardConfig['Shop'],
						isInMaintenance: cardConfig['Shop'].isInMaintenance,
						link:
							chargeBee.loginURL !== ''
								? chargeBee.loginURL
								: 'https://ludusonlinepayments.chargebeeportal.com/portal/v2/login?forward=portal_main&be_id=Spain-Online-Payments',
						cardNumber: cardNumber,
					})
					break
				case 'HUB':
					userServiceCards.push({
						...cardConfig['HUB'],
						isInMaintenance: cardConfig['HUB'].isInMaintenance,
						cardNumber: cardNumber
					})
					break
				case 'LRS':
					userServiceCards.push({
						...cardConfig['LRS'],
						isInMaintenance: cardConfig['LRS'].isInMaintenance,
						cardNumber: cardNumber
					})
					break
				default:
					break
			}
		}
	}

	setUserServices(userServiceCards)
}

export default useUserServiceCards
