import { createLudusLauncherPath } from '../utils/createLudusLauncherPath'

const useLaunchHUB = (user, language, addSuccess, addError, translate) => {
	const handleLaunchHUB = async () => {
		const path = await createLudusLauncherPath(user, language)

		const newWindow = window.open(path, '_blank')
		setTimeout(() => {
			if (newWindow && newWindow.closed) {
				addSuccess(`${translate('success_hub')}`)
			} else {
				newWindow?.close()
				addError(`${translate('error_hub')}`)
			}
		}, 3000)
	}

	return { handleLaunchHUB }
}

export default useLaunchHUB
