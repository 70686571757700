import axios from 'axios'

export type LoginChargeBeeResponse = {
	portal_session: string
}

export default async function chargeBeeLogin(email: string, apiVersion: string ) {
	return await axios
		.post<LoginChargeBeeResponse>(
			`${process.env.REACT_APP_AUTH_API_URL}/api/chargeBee/login`,
			{ email },
			{
				headers: {
					'Content-Type': 'application/json',
					Authorization: `${process.env.REACT_APP_AUTH_API_TOKEN}`,
					Accept: 'application/json',
					'x-api-version': `${apiVersion}`
				},
			}
		)
		.then((response) => {
			return response.data
		})
		.catch((error) => {
			return error.response.data
		})
}
