import {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from 'react'

type AppVersionType = {
	portal: string
	authAPI: string
	path: string
}

type VersionContextType = {
	appVersion: AppVersionType
	setVersion: (portal: string, authAPI: string) => void | undefined
	determinePathVersion: (portalVersion: string) => string
	removeVersion: () => void;
}

type Props = {
	children: ReactNode
}

const VersionContext = createContext<VersionContextType>({
	appVersion: undefined,
	setVersion: undefined,
	determinePathVersion: undefined,
	removeVersion: undefined
})

export const useVersion = () => useContext(VersionContext)

const VersionProvider: FC<Props> = ({ children }) => {

	const defaultLoginVersion = determinePathVersion(
		process.env.REACT_APP_LOGIN_API_VERSION || '1.0.0'
	)
	const [appVersion, setAppVersion] = useState<AppVersionType | undefined>({
		portal: '',
		authAPI: '',
		path: '',
	})

	function determinePathVersion(portalVersion: string) {
		const numVersion = portalVersion.split('.')[0]
		return `v${numVersion}`
	}

	useEffect(() => {
		if (appVersion.path === '') {
			setAppVersion({
				portal: '',
				authAPI: '',
				path: defaultLoginVersion,
			})
		}
	}, [])


	const contextValue = {
		appVersion: appVersion,
		determinePathVersion: useCallback(
			(portalVersion: string) => determinePathVersion(portalVersion),
			[]
		),

		setVersion: useCallback(
			(portal: string, authAPI: string) =>
				setAppVersion({
					portal: portal,
					authAPI: authAPI,
					path: determinePathVersion(portal),
				}),
			[]
		),
		removeVersion: useCallback(
			() =>
				setAppVersion({
					portal: '',
					authAPI: '',
					path: defaultLoginVersion,
				}),
			[]
		),
	}

	return (
		<VersionContext.Provider value={contextValue}>
			{children}
		</VersionContext.Provider>
	)
}

export default VersionProvider
