const useLogout = (cookies, removeCookie, removeUser) => {
	Object.keys(cookies).forEach((cookie) => {
		if (cookie === 'AuthToken' || cookie === 'AuthToken_JWE') {
			removeCookie(cookie, {
				path: '/',
				maxAge: 2592000,
			})
		}
		removeCookie(cookie, {
			path: '/',
			domain: 'ludusservers.com',
		})
	})
	removeUser()
	sessionStorage.clear()
	localStorage.clear()
}

export default useLogout
